export default function (props: any) {
    return (
        <svg viewBox="0 0 28 28" fill="currentColor" width="1em" height="1em" {...props}>
            <path
                d="M14 .65c-1.935 0-3.751.355-5.45 1.068C6.854 2.43 5.374 3.4 4.113 4.63A13.905 13.905 0 0 0 1.11 8.959C.37 10.614 0 12.385 0 14.272c0 1.886.37 3.657 1.11 5.313a13.905 13.905 0 0 0 3.002 4.328c1.261 1.23 2.741 2.206 4.439 2.927 1.698.721 3.514 1.082 5.449 1.082s3.751-.36 5.45-1.082c1.697-.721 3.177-1.697 4.438-2.927a13.906 13.906 0 0 0 3.002-4.328c.74-1.656 1.11-3.427 1.11-5.313 0-1.887-.37-3.658-1.11-5.313a13.906 13.906 0 0 0-3.002-4.328C22.627 3.4 21.147 2.43 19.45 1.718 17.751 1.005 15.935.649 14 .649zm3.3 9.405h-2.076c-.133 0-.257.074-.37.222a.84.84 0 0 0-.171.527v1.498H17.3l-.37 2.109h-2.248v6.325h-2.476v-6.325H9.96v-2.109h2.248v-1.249c0-.887.285-1.65.854-2.288.57-.639 1.29-.958 2.163-.958H17.3v2.248z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
