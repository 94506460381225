import request from '@/utils/request';

// 是否同意ccpa
export const userPrivacyPolicy = (api: string, data: any) => {
    return request(api, {
        method: 'post',
        data,
        needToken: true,
    });
};
