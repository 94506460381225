export default function IconCart() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="jsx-444279368">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.60547 7.20825H19.5285C20.2494 7.20825 20.8337 7.7926 20.8337 8.51342C20.8337 8.63679 20.8162 8.75954 20.7818 8.87799L18.6935 16.0564C18.5314 16.6137 18.0207 16.997 17.4403 16.997H9.4327C8.85229 16.997 8.34161 16.6137 8.17948 16.0564L5.60547 7.20825Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                className="jsx-444279368"
            ></path>
            <path
                d="M5.99949 8.51292L5.26754 6.1665C5.09747 5.62131 4.59269 5.25 4.02159 5.25H2.08398"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                className="jsx-444279368"
            ></path>
            <path
                d="M9.37565 20.875C9.95095 20.875 10.4173 20.4086 10.4173 19.8333C10.4173 19.258 9.95095 18.7917 9.37565 18.7917C8.80035 18.7917 8.33398 19.258 8.33398 19.8333C8.33398 20.4086 8.80035 20.875 9.37565 20.875Z"
                fill="black"
                className="jsx-444279368"
            ></path>
            <path
                d="M17.7087 20.875C18.284 20.875 18.7503 20.4086 18.7503 19.8333C18.7503 19.258 18.284 18.7916 17.7087 18.7916C17.1334 18.7916 16.667 19.258 16.667 19.8333C16.667 20.4086 17.1334 20.875 17.7087 20.875Z"
                fill="black"
                className="jsx-444279368"
            ></path>
            <path d="M10.8262 10.4826V13.7481" stroke="black" strokeWidth="1.5" strokeLinecap="round" className="jsx-444279368"></path>
            <path d="M13.4365 10.4826V13.7481" stroke="black" strokeWidth="1.5" strokeLinecap="round" className="jsx-444279368"></path>
            <path d="M16.0469 10.4826V13.7481" stroke="black" strokeWidth="1.5" strokeLinecap="round" className="jsx-444279368"></path>
        </svg>
    );
}
