'use client';

import { useEffect } from 'react';

export default function GlobalCheck() {
    useEffect(() => {
        // 防止iframe加载
        if (window && window.top && window.self && window.top !== window.self) {
            try {
                debugger;
                if (!window.top.location.hostname.includes('.ecoflow.com')) {
                    window.top.location = window.location;
                }
            } catch (error) {}
        }
    }, []);
    return null;
}
