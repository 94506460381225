'use client';
import { getIsPadWidth } from '@elem/utils/devices';
import dynamic from 'next/dynamic';
import { useState, useEffect } from 'react';
import IconStore from './icons/IconStore';
import gtm from '@/utils/gtm';
import { loginUser } from '@/utils/cookie';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';

const StoreCN = dynamic(() => import('./StoreCN'), {
    ssr: false,
});

const Store = ({ locale }: any) => {
    const [showCnWin, setShowCnWin] = useState(false);
    const isMobile = getIsPadWidth(); //是否是移动端

    // 点击商城
    const authLogin = async (authSignIn: any) => {
        // 中国站 移动设备点击显示 电脑hover显示
        if (locale === 'cn') {
            setShowCnWin(!showCnWin);
            return;
        }
        gtm.push({
            event: 'uaEvent',
            event_name: 'top_function',
            button_name: 'Store',
        });
        let winOpen: any;
        //手机不使用window.open打开
        if (isMobile) {
        } else {
            winOpen = window.open('', '_blank');
        }
        try {
            let url = 'https://us.ecoflow.com';
            switch (locale) {
                case 'cn':
                    url = 'https://mall.jd.com/index-1000363402.html';
                    break;
                case 'jp':
                    url = 'https://jp.ecoflow.com';
                    break;
                case 'cis':
                    url = 'https://ecoflow-russia.com/';
                    break;
                default:
                    url = `https://${locale}.ecoflow.com`;
                    break;
            }
            const token = loginUser()?.token || '';

            if (token) {
                const res = await authSignIn({ url });
                if (res.code === '0') {
                    url = res.data;
                }
            }
            // 第三方shopify登录，需要在cookie写入token信息，目前没有选择地区，暂时写us地区
            document.cookie = `us-token=${token};expires=7;` + `domain=.ecoflow.com;path=/`;
            if (isMobile) {
                location.href = saveCookieForShopify(url, locale) || '';
            } else {
                winOpen.location = saveCookieForShopify(url, locale); //兼容safari浏览器
            }
        } catch (err) {
            console.log(err);
        }
    };

    const authLoginHandle = () => {
        (async () => {
            const { authSignIn } = await import('@/service/login');
            authLogin(authSignIn);
        })();
    };

    useEffect(() => {
        if (!isMobile && locale === 'cn') {
            setShowCnWin(true);
        }
    }, []);

    return (
        <div className="header-tools-item store-icon">
            <button aria-label="store">
                <IconStore onClick={authLoginHandle} />
            </button>
            {showCnWin && <StoreCN />}
        </div>
    );
};

export default Store;
