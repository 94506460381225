export const giftIcon = () => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 5.03101V13.6977" stroke="#010101" stroke-linecap="round" stroke-linejoin="round" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00033 2.69763H6.33366C7.25413 2.69763 8.00033 3.44382 8.00033 4.3643V5.3643H6.00033C5.26395 5.3643 4.66699 4.76734 4.66699 4.03097C4.66699 3.29459 5.26395 2.69763 6.00033 2.69763Z"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99967 2.69763H9.66634C8.74587 2.69763 7.99967 3.44382 7.99967 4.3643V5.3643H9.99967C10.7361 5.3643 11.333 4.76734 11.333 4.03097C11.333 3.29459 10.7361 2.69763 9.99967 2.69763Z"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3.33301 8.03101H12.6663V13.3643C12.6663 13.7325 12.3679 14.031 11.9997 14.031H3.99967C3.63148 14.031 3.33301 13.7325 3.33301 13.3643V8.03101Z"
            stroke="black"
        />
        <rect width="12" height="2.66667" rx="0.666667" transform="matrix(1 0 0 -1 2 8.03101)" stroke="black" />
    </svg>
);

export const copyIcon = () => (
    <svg className="code-copy" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.00342" y="5.49841" width="7.50476" height="7.50476" rx="0.625397" stroke="#3F68E0" stroke-width="0.938095" />
        <path
            d="M5.00305 10.5016H3.12686C2.78146 10.5016 2.50146 10.2216 2.50146 9.87619V3.62222C2.50146 3.27683 2.78146 2.99683 3.12686 2.99683H9.38083C9.72623 2.99683 10.0062 3.27683 10.0062 3.62222V5.49841"
            stroke="#3F68E0"
            stroke-width="0.938095"
        />
    </svg>
);

export const couponIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.50034 5.43513L10.3756 2.64065C10.7193 2.50093 11.111 2.66878 11.2469 3.01405L12.2 5.43513M5.89966 8.29549H7.70034M5.89966 10.1243H10.1003M14 7.26397V6.1018C14 5.73361 13.7015 5.43513 13.3333 5.43513H2.66667C2.29848 5.43513 2 5.73361 2 6.1018V7.26397C2.89966 7.26397 3.8 7.87335 3.8 9.0928C3.8 10.3123 2.90034 10.9216 2 10.9216V12.0838C2 12.452 2.29848 12.7505 2.66667 12.7505H13.3333C13.7015 12.7505 14 12.452 14 12.0838V10.9216C13.1003 10.9216 12.2 10.0069 12.2 8.94011C12.2 7.87335 13.1003 7.26397 14 7.26397Z"
            stroke="#A06314"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
