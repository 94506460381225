'use client';
import getCurrentEffect from 'lowcode/common/BarWin/getCurrentEffect';
import * as CompS from 'lowcode/plugins/glo-bar-top/CompS';
import Empty from 'lowcode/utils/Empty';
import { Fragment } from 'react';

const defaultTemp: any = {
    ...CompS,
};

export default function GloBarTop({ gloBarObject = {} }: { gloBarObject: any }) {
    const { theTemp = [], theData = {} } = gloBarObject;

    const id = theTemp[0]?.id || '';
    if (!id) {
        return null;
    }
    const data = theData[id] || {};

    // 根据配置的页面范围展示与不展示
    const isShowBar = getCurrentEffect(data);
    if (!isShowBar) {
        return null;
    }

    return (
        <div className="glo-bar-top">
            {theTemp?.map((item: any) => {
                const id = item.id;
                const ContentA = defaultTemp[item.name] || Empty;

                return (
                    <Fragment key={id}>
                        <ContentA lowCodeType="preview" id={id} data={theData[id] || {}} />
                    </Fragment>
                );
            })}
        </div>
    );
}
