'use client';
import dynamic from 'next/dynamic';
import { useState, useEffect } from 'react';
import { useGlobal } from '@/utils/useGlobal';
import { setLocalStorage, getLocalStorage } from '@/utils/localStorage';
import { efFetch } from '@/utils';
import { getCountryRegion } from '@/utils/countryRegion';
import getLocal from '@/app/utils/getLocal';

// 两种场景可以使用 lazy 或 dynamic 1.非首屏渲染的模块（包含条件渲染）2.拆包大小并且做seo优化可以使用 ssr: true
const RegionHeader: any = dynamic(() => import('./RegionHeader'), { ssr: false });
const EFRegion: any = dynamic(() => import('@/components/UI/EFRegion'), { ssr: false });

const readyLangsFullSite = JSON.parse(process.env.NEXT_PUBLIC_READY_LANG_FULL_SITE || '[]');

const RegionHeaderHidePages: any[] = ['/delta-pro-ultra', '/smart-home-panel-2'];

export default function RegionPopup({ callback }: any) {
    const { isMobile, isCypressTest } = useGlobal();
    const { locale, pathname } = getLocal();
    const [visible, setVisible] = useState(false);
    const [showRegionPopup, setShowRegionPopup] = useState('0');

    const setRegionHide = (num: string) => {
        if (num == '3') {
            //打开弹窗
            if (isMobile) {
                window.location.href = `${window.location.origin}/${locale}/region`;
            } else {
                setVisible(true);
            }
        }
        if (num === '3' || num === '4') {
            setLocalStorage(`macIp_${locale}`, '1'); //把该语言的ip提示关掉
        }

        if (num === '1') {
            callback && callback(num);
        }
        // 点击关闭
        if (num === '4') {
            callback && callback(num);
        }
        setShowRegionPopup(num);
    };

    // 在浏览器端获取ip接口并进行相应判断
    const setSomethingFromIp = async () => {
        if (isCypressTest) {
            return null;
        }
        // TODO: 特殊站点做如下处理，例如：United Kingdom的CountryCode为GB, 需要处理为uk
        const COUNTRY_CODE_MAP = {
            ...readyLangsFullSite.reduce((acc, current) => ({ ...acc, [current]: current }), {}),
            gb: 'uk',
            ru: 'cis',
            sa: 'sa',
        };

        // 判断当前访问网站的语言是不是当地地区
        const isNativeRegion = (lang: string): boolean => {
            return lang && COUNTRY_CODE_MAP[lang] && lang !== locale;
        };

        // localStorage中保存的API后台获取的MAC物理地址语言
        const macIpLocale = getLocalStorage(`macIp_${locale}`);
        let macIp = getLocalStorage('macIp');
        if (COUNTRY_CODE_MAP[macIp]) {
            macIp = COUNTRY_CODE_MAP[macIp];
        }
        // if (macIp === 'gb') {
        //     macIp = 'uk';
        // }
        if (!macIp && !macIpLocale) {
            const apiUrl = getCountryRegion(locale) === 'eu' ? process.env.NEXT_PUBLIC_LOGIN_HOST_EU : process.env.NEXT_PUBLIC_LOGIN_HOST;
            const GEO_IP_API = `${apiUrl}/website/address/finGeoByIp`;
            const COUNTRY_API = `${apiUrl}/general/location-api/countriesGroupByKey`;
            const res = await efFetch(GEO_IP_API);
            let countryCode = res?.data?.countryCode?.toLowerCase();
            // TODO: debug
            // let countryCode = 'gb';
            // 获取国家列表及匹配对应CountryCode的数据中心地址(eu/us)
            if (COUNTRY_CODE_MAP[countryCode]) {
                countryCode = COUNTRY_CODE_MAP[countryCode];
            } else {
                let countryListData = [];
                try {
                    const { data = [] } = await efFetch(COUNTRY_API);
                    countryListData = data;
                } catch (err) {}
                countryListData = countryListData.reduce((acc, current) => [...acc, ...current.countryResponses], []);
                const { dataCenterCode = 'us' } = countryListData.find((v) => v.countryCode === (countryCode || '').toUpperCase()) || {};
                // TODO: 后端预留其余多站点，但现版本仅考虑us和eu
                countryCode = dataCenterCode === 'us' ? 'us' : 'eu';
            }
            setLocalStorage('macIp', countryCode);
            if (isNativeRegion(countryCode)) {
                setRegionHide('1');
            }
        }
        if (macIp && isNativeRegion(macIp) && (!macIpLocale || macIpLocale !== '1')) {
            setRegionHide('1');
        }
    };

    useEffect(() => {
        readyLangsFullSite.includes(locale) && showRegionPopup === '0' && setSomethingFromIp();
    }, []);
    //readyLangsFullSite.includes(locale) && showRegionPopup === '0' && setSomethingFromIp();

    return (
        <>
            {showRegionPopup === '1' && !RegionHeaderHidePages.includes(pathname) && <RegionHeader callback={setRegionHide} />}
            {showRegionPopup === '3' && !isMobile && <EFRegion visible={visible} onClose={() => setVisible(false)} />}
        </>
    );
}
