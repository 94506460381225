'use client';
import { i18nText } from './i18nText';
import gtm from '@/utils/gtm';
import getLocal from '@/app/utils/getLocal';
import { sharelinkbylang } from '@/constants';
import EFfbGray from '@/components/UI/svg/Footer/IconNew/EFfbGray';
import EFtwGray from '@/components/UI/svg/Footer/IconNew/EFtwGray';
import EFinsGray from '@/components/UI/svg/Footer/IconNew/EFinsGray';
import EFTiktok from '@/components/UI/svg/Footer/IconNew/EFTiktok';
import EFnofollowGray from '@/components/UI/svg/Footer/IconNew/EFnofollowGray';
import EFLine from '@/components/UI/svg/crowdfunding/EFLine';

const IconList = () => {
    const { locale } = getLocal();
    const { FACEBOOK, TWITTER, INSTAGRAM, YOUTUBE, Tiktok } = sharelinkbylang(locale);
    return (
        <div className="icon-list">
            {/* 2023.0731 （JP）页面更新 https://ecoflow.feishu.cn/docx/ZYd8dRi0KoBJmpxq19QcPD4enCg */}
            {locale === 'jp' && (
                <a
                    className="icon"
                    rel="nofollow noreferrer"
                    href={'https://lin.ee/NU9u4ah'}
                    target="_blank"
                    aria-label={i18nText.LINE}
                    onClick={() =>
                        gtm.push({
                            event: 'uaEvent',
                            event_name: 'ecoflow_social',
                            button_name: 'Line',
                        })
                    }
                >
                    <EFLine className="img-wrapper" />
                </a>
            )}
            <a
                className="icon"
                rel="nofollow noreferrer"
                href={FACEBOOK}
                target="_blank"
                aria-label={i18nText.FACEBOOK}
                onClick={() =>
                    gtm.push({
                        event: 'uaEvent',
                        event_name: 'ecoflow_social',
                        button_name: 'Facebook',
                    })
                }
            >
                <EFfbGray className="img-wrapper"></EFfbGray>
            </a>
            <a
                className="icon"
                rel="nofollow noreferrer"
                href={TWITTER}
                target="_blank"
                aria-label={i18nText.TWITTER}
                onClick={() =>
                    gtm.push({
                        event: 'uaEvent',
                        event_name: 'ecoflow_social',
                        button_name: 'Twitter',
                    })
                }
            >
                <EFtwGray className="img-wrapper"></EFtwGray>
            </a>
            <a
                className="icon"
                rel="nofollow noreferrer"
                href={INSTAGRAM}
                target="_blank"
                aria-label={i18nText.INSTAGRAM}
                onClick={() =>
                    gtm.push({
                        event: 'uaEvent',
                        event_name: 'ecoflow_social',
                        button_name: 'Instagram',
                    })
                }
            >
                <EFinsGray className="img-wrapper"></EFinsGray>
            </a>
            <a
                className="icon"
                rel="nofollow noreferrer"
                href={YOUTUBE}
                target="_blank"
                aria-label={i18nText.YOUTUBE}
                onClick={() =>
                    gtm.push({
                        event: 'uaEvent',
                        event_name: 'ecoflow_social',
                        button_name: 'Youtube',
                    })
                }
            >
                <EFnofollowGray className="img-wrapper"></EFnofollowGray>
            </a>
            {['us', 'ca', 'uk'].includes(locale) && (
                <a
                    className="icon"
                    rel="nofollow noreferrer"
                    href={Tiktok}
                    target="_blank"
                    aria-label={i18nText.TIKTOK}
                    onClick={() =>
                        gtm.push({
                            event: 'uaEvent',
                            event_name: 'ecoflow_social',
                            button_name: 'Tiktok',
                        })
                    }
                >
                    <EFTiktok className="img-wrapper" />
                </a>
            )}
        </div>
    );
};

export default IconList;
