export default function (props: any) {
    return (
        <svg viewBox="0 0 28 28" fill="currentColor" width="1em" height="1em" {...props}>
            <path
                d="M23.898 4.644C21.164 1.981 17.865.65 14 .65c-3.865 0-7.164 1.332-9.898 3.995C1.367 7.308 0 10.521 0 14.286c0 3.764 1.367 6.978 4.102 9.641 2.734 2.663 6.033 3.995 9.898 3.995 3.865 0 7.164-1.332 9.898-3.995C26.633 21.264 28 18.05 28 14.286c0-3.765-1.367-6.978-4.102-9.642zm-1.312 12.678c-.037.64-.264 1.243-.684 1.811-.419.568-.92.906-1.504 1.012-3.9.284-8.166.284-12.796 0-.584-.07-1.085-.4-1.504-.985-.42-.586-.647-1.199-.684-1.838a34.376 34.376 0 0 1 0-6.126c.037-.603.264-1.198.684-1.784.419-.586.92-.915 1.504-.986 3.791-.284 8.057-.284 12.796 0a1.96 1.96 0 0 1 1.559.853c.383.532.592 1.136.629 1.81a35.58 35.58 0 0 1 0 6.233zm-10.336.373 5.25-3.41-5.25-3.408v6.818z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
