import EfProgressCircle from '@elem/ui/ef-progress-circle';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { checkUserGainReissue, gainReissueCount } from '../comps/apis';
import '../css/win.scss';
import { textLangCommon } from '../wins/textLangCommon';
import WinCommon from '../wins/WinCommon';
import './index.scss';
export default function CheckInCountdown() {
    const { locale = 'us', query } = useRouter();
    const signInCampaignId = query?.signInCampaignId;
    if (!signInCampaignId) {
        return null;
    }
    const timeOriginRef = useRef<any>(null);
    const timeRef = useRef<any>(null);
    const timeNumRef = useRef(0);
    const [timeNumA, setTimeNumA] = useState(0);
    const [timeNumB, setTimeNumB] = useState(100);
    const [isShow, setIsShow] = useState(false);
    const [isSuccessA, setIsSuccessA] = useState(false);
    const TextA = textLangCommon[locale] || textLangCommon['us'];
    const pageRef = useRef('');
    const [backUrl, setBackUrl] = useState('');

    // 获得打卡
    const gainReissueCountHandle = async (requestNum: number, openSuccess?: boolean) => {
        const res = await gainReissueCount(locale, {
            signInCampaignId: signInCampaignId,
            gainConfig: pageRef.current,
            gainType: 2,
            requestNum: requestNum,
        });
        if (res?.code === '0' && openSuccess) {
            setIsSuccessA(true);
        }
    };
    useEffect(() => {
        // 倒计时
        const startTimeHandle = (number: number) => {
            timeNumRef.current = number;
            setTimeNumA(number);
            timeRef.current = setInterval(() => {
                const timeNumA = timeNumRef.current;
                if (!timeNumA) {
                    gainReissueCountHandle(2, true);
                    return clearInterval(timeRef.current);
                }
                const timeNumA2 = timeNumA - 1;

                const timeNumB1: number = (timeNumA2 * 100) / timeOriginRef.current;
                const timeNumB2 = parseInt(`${timeNumB1}`);
                setTimeNumA(timeNumA2);
                setTimeNumB(timeNumB2);
                timeNumRef.current = timeNumA2;
            }, 1000);
        };

        // 打卡信息
        const checkUserGainReissueHandle = async (signInCampaignId: string) => {
            const res = await checkUserGainReissue(locale, signInCampaignId);
            if (res?.code === '0') {
                const data = res?.data || {};
                const gainConfig = data?.gainConfig;
                if (gainConfig && typeof gainConfig === 'string') {
                    const gainConfigArr = gainConfig.split(',');
                    pageRef.current = gainConfigArr[0];
                    const secondsA = gainConfigArr[1] || '0';
                    const secondsB = Number(secondsA);
                    timeOriginRef.current = secondsB;
                    startTimeHandle(secondsB);
                    setIsShow(true);
                    gainReissueCountHandle(1);
                }
            }
        };
        if (typeof signInCampaignId === 'string' && signInCampaignId) {
            checkUserGainReissueHandle(signInCampaignId);
        }
    }, []);
    useEffect(() => {
        try {
            const urlA = new URL(location.href);
            urlA.pathname = `/${locale}/ecocredits`;
            const searchA = urlA.searchParams;

            const redirectA = searchA.get('redirect');
            const dateA = searchA.get('date');
            if (redirectA) {
                setBackUrl(`${redirectA}?overDown=check-in&date=${dateA}`);
            }
        } catch (error) {}
    }, []);
    const closeHandle = () => {
        setIsSuccessA(false);
    };
    if (!isShow) {
        return null;
    }

    return (
        <>
            {timeNumB > 0 && (
                <div className="ef-check-in-CheckInCountdown">
                    <EfProgressCircle processText={`${timeNumA}`} process={timeNumB} />
                </div>
            )}
            {isSuccessA && (
                <WinCommon
                    title={TextA.title}
                    desc={TextA.visitPageDesc}
                    btnText={TextA.visitPageBack}
                    callback={closeHandle}
                    btnHref={backUrl}
                    btnDataLayer={{
                        event: 'uaEvent',
                        event_name: 'jump_button',
                        position: 'Make up for check-in',
                        button_name: TextA.visitPageBack,
                        url: backUrl,
                    }}
                />
            )}
        </>
    );
}
