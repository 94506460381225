import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';
import { FormatMoneyLocal } from '@elem/ui-common/utils/FormatMoneyLocal';
import classNames from 'classnames';
import editorEventsBus from 'lowcode/utils/editorEventsBus';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import EfBundlesCalculator from '../ef-bundles-calculator';
import EfImg from '../ef-img';
import IconCart from './common/IconCart';
import IconClose from './common/IconClose';
import { langText } from './common/langText';
import { getIndexItemForAdd, getTotalPrice } from './common/utils';
import './index.scss';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';
import { CouponGiftProductItemType, CouponGiftType, CouponHitProductType, CouponType, CouponCountdownDateType } from './common/type';
import { useGlobal } from '@/utils/useGlobal';
import { loginUrl } from '@/service/util';
import request from '@/utils/request';
import ClipboardJS from 'clipboard';
import { getHostApi } from '@/utils';
import getDefaultHeader from '@/utils/singUtil/getDefaultHeader';
import { couponIcon, copyIcon, giftIcon } from './common/icon';
import { getIsLogin } from '@/utils/cookie';
/**
 * 维护:谢保林
 */
interface EfShopingCartOperaItemProps {
    item: any;
    quantity: number;
}
interface EfShopingCartProps {
    className?: string;
    callback?: any;
}

const EfShopingCart: React.FC<EfShopingCartProps> = ({ className, callback }) => {
    const { locale } = getLocal();
    const { getSystemTime } = useGlobal();
    const textA = langText[locale] || langText['us'];
    const prefixCls = `ef-shoping-cart`;
    const [isClient, setIsClient] = useState(false);
    const [isOpen, setIsOpen] = useState(false); //是否打开购物车
    const refItems = useRef([]); //不使用这个的话,editorEventsBus事件里面的 items 不变化
    const [cartList, setCartList] = useState([]); //购物车列表
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
        [`${prefixCls}-open`]: isOpen,
    });
    const totalObj = getTotalPrice(cartList); // 总价,总量
    const [totalPrice, setTotalPrice] = useState(totalObj.totalPrice); //总价
    const [totalQuantity, setTotalQuantity] = useState(totalObj.totalQuantity); // 总量

    // https://ecoflow.feishu.cn/docx/Hm3OdAG7Toi9ODxCCaCcCQqDnZ5
    const [coupon, setCoupon] = useState({} as CouponType);
    const [copyed, setCopyed] = useState('');
    const [copyedGift, setCopyedGift] = useState(false);
    const [countdownDateObj, setCountdownDateObj] = useState({ d: '00', h: '00', m: '00', s: '00' } as CouponCountdownDateType);
    const sysTime = getSystemTime();
    let serverTime = sysTime; // 变
    const timer: any = useRef(null);
    const isLogin = getIsLogin();

    // 不能删，配合data-clipboard-text使用的对象
    const clipboard = typeof window !== 'undefined' ? new ClipboardJS(`.ef-shoping-cart .cart-coupon .coupon-code`) : null;

    const copyText = (cart: any) => {
        setCopyed(cart?.variantId + (cart?.parentVariantId ? `_${cart?.parentVariantId}` : ''));
        let timer: any = setTimeout(() => {
            setCopyed('');
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
        }, 2000);
    };

    const copyTextGift = () => {
        navigator.clipboard.writeText(coupon?.code || '');
        setCopyedGift(true);
        let timer: any = setTimeout(() => {
            setCopyedGift(false);
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
        }, 2000);
    };

    const onVisibleChange = async () => {
        if (window.document.visibilityState === 'visible') {
            serverTime = getSystemTime();
            // 测试环境异常
            if (serverTime < 1000000) {
                (async () => {
                    const API_HOST = getHostApi(locale).NEXT_PUBLIC_API_HOST;
                    const headers: any = getDefaultHeader(locale);

                    try {
                        const spuData = await fetch(`${API_HOST}/spuConformity?locale=${locale}`, {
                            headers,
                        });
                        const globalData = await spuData.json();
                        serverTime = globalData?.data?.timestamp || new Date().getTime();
                    } catch (e) {
                        console.error(e);
                    }
                })();
            }
        }
    };

    const format = (time: number) => {
        const ts = Math.floor(time / 1000);
        const d = String(Math.floor(ts / 3600 / 24)).padStart(2, '0');
        const h = String(Math.floor((ts / 3600) % 24)).padStart(2, '0');
        const m = String(Math.floor((ts / 60) % 60)).padStart(2, '0');
        const s = String(Math.floor(ts % 60)).padStart(2, '0');
        setCountdownDateObj({ d, h, m, s });
    };

    useEffect(() => {
        if (cartList?.length > 0 && isLogin && Object.keys(coupon).length > 0) {
            // 测试环境异常
            if (serverTime < 1000000) {
                (async () => {
                    const API_HOST = getHostApi(locale).NEXT_PUBLIC_API_HOST;
                    const headers: any = getDefaultHeader(locale);

                    try {
                        const spuData = await fetch(`${API_HOST}/spuConformity?locale=${locale}`, {
                            headers,
                        });
                        const globalData = await spuData.json();
                        serverTime = globalData?.data?.timestamp || new Date().getTime();
                    } catch (e) {
                        console.error(e);
                    }
                })();
            }

            if (typeof window !== 'undefined') {
                window.document.addEventListener('visibilitychange', onVisibleChange);
            }

            if (coupon.startTime && coupon.endTime) {
                const [startTime, endTime] = [
                    new Date(coupon.startTime.replaceAll('-', '/') + ' GMT+8').getTime(),
                    new Date(coupon.endTime.replaceAll('-', '/') + ' GMT+8').getTime(),
                ];
                if (startTime < endTime && startTime <= serverTime && serverTime < endTime) {
                    format(endTime - serverTime);
                    timer.current = setInterval(() => {
                        format(endTime - serverTime);
                        serverTime += 1000;
                        if (serverTime >= endTime) {
                            clearInterval(timer.current);
                            timer.current = null;
                        }
                    }, 1000);
                }
            }

            return () => {
                clearInterval(timer.current);
            };
        }
    }, [coupon]);

    useEffect(() => {
        // console.log('cartList', cartList);
        // 查询购物车优惠券 Dicount Coupon / 礼品卡 Gift Card
        const getCartCouponGift = async () => {
            const data: CouponGiftType = {
                source: 'CART',
                productList: cartList.reduce((arr: CouponGiftProductItemType[], cart: any) => {
                    arr.push({
                        price: Number(cart?.discountPrice || cart?.price),
                        quantity: cart?.quantity,
                        productId: cart?.productId,
                        variantId: cart?.variantId,
                    });
                    return arr;
                }, [] as CouponGiftProductItemType[]),
            };
            // console.log('data', data);
            try {
                const res = await request(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/cart/coupon/listForWeb`, {
                    method: 'POST',
                    data,
                    needToken: true,
                });
                // console.log('res', res);
                if (res?.code === '0') {
                    if (res?.data?.coupons?.length > 0) {
                        setCoupon(res?.data?.coupons[0]);
                    } else {
                        setCoupon({} as CouponType);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        if (isLogin && cartList?.length > 0) {
            getCartCouponGift();
        }
    }, [cartList]);

    const renderCountdown = useMemo(() => {
        return (
            <div className="coupon-countdown">
                <span className="coundown-text">{textA.countdown}</span>
                <div className="countdown-time">
                    <div className="day-box date-list">
                        <span className="day date-number">{countdownDateObj?.d}</span>
                    </div>
                    <div className="hour-box date-list">
                        <span className="hour date-number">{countdownDateObj?.h}</span>
                    </div>
                    <div className="minute-box date-list">
                        <span className="minute date-number">{countdownDateObj?.m}</span>
                    </div>
                    <div className="second-box date-list">
                        <span className="second date-number">{countdownDateObj?.s}</span>
                    </div>
                </div>
            </div>
        );
    }, [countdownDateObj, coupon]);

    useEffect(() => {
        // 添加到购物车|可增加,也可删除
        editorEventsBus.on('efshopingcart-add', (list: EfShopingCartOperaItemProps[], callback: any) => {
            const cartList2 = JSON.parse(JSON.stringify(refItems.current || []));
            const list2 = list || [];
            list2.forEach((addObj: any, index: number) => {
                const addItem = addObj.item || {}; //添加的变量
                const addQuantity2 = addObj.quantity; //添加的数量
                let addQuantity = typeof addQuantity2 === 'number' ? addQuantity2 : 1; //传入元素的数量
                const index2 = getIndexItemForAdd(cartList2, addItem); //传入元素的下标
                const maxCanBuyCount = Number(`${addItem.maxCanBuyCount}`); //最多能购买
                if (index2 === -1) {
                    // 列表中不存在, 新增选项(只新增购买数量为正的)
                    if (addQuantity > 0) {
                        // 如果有最大购买量
                        if (maxCanBuyCount && typeof maxCanBuyCount === 'number') {
                            addQuantity = Math.min(addQuantity, maxCanBuyCount);
                        }
                        cartList2.push({
                            ...addItem,
                            quantity: addQuantity,
                        });
                    }
                } else {
                    // 列表中存在,且数量为负数,减少
                    const item2 = cartList2[index2];
                    if (addObj.isChangeQuantityForParams) {
                        // 是否使用传入的数量
                        item2.quantity = addQuantity;
                    } else {
                        // 如果有最大购买量
                        let newQuantity = (item2.quantity || 0) + addQuantity;
                        if (maxCanBuyCount && typeof maxCanBuyCount === 'number') {
                            newQuantity = Math.min(newQuantity, maxCanBuyCount);
                        }
                        item2.quantity = newQuantity;
                    }

                    // 数量少于等于0,删掉
                    if (item2.quantity <= 0) {
                        cartList2.splice(index2, 1);
                    }
                }
            });

            setIsClient(true);
            setListHandle(cartList2);
            openCartHandle();
            callback && callback(cartList2);
        });
    }, []);
    // 重新更新 list
    const setListHandle = (cartList2: any) => {
        setCartList(cartList2);
        refItems.current = cartList2;
        const totalObj2 = getTotalPrice(cartList2);
        setTotalPrice(totalObj2.totalPrice); // 总价
        setTotalQuantity(totalObj2.totalQuantity); // 总量
    };
    // 打开购物车--执行
    const openCartHandle = () => {
        setIsOpen(true);
    };
    // 关闭购物车--执行
    const closeCartHandle = () => {
        setIsOpen(false);
    };
    // 切换购物车
    const toggleCartHandle = () => {
        if (isOpen) {
            closeCartHandle();
        } else {
            openCartHandle();
        }
    };
    // 购物车数量发生变化
    const inventoryChangeCallback = (quantity: number, item: any) => {
        const cartList2 = JSON.parse(JSON.stringify(cartList || []));
        const index2 = getIndexItemForAdd(cartList2, item);
        if (index2 > -1 && quantity === 0) {
            // 直接删除
            cartList2.splice(index2, 1);
        } else if (index2 > -1 && quantity) {
            const maxCanBuyCount = Number(`${item.maxCanBuyCount}`); //最多能购买
            let quantity2 = quantity;
            if (maxCanBuyCount && typeof maxCanBuyCount === 'number') {
                quantity2 = Math.min(quantity2, maxCanBuyCount);
            }
            cartList2[index2].quantity = quantity2; //更新库存
        }
        setListHandle(cartList2);
        editorEventsBus.emit('efshopingcart-num-change', cartList2);
    };

    // 去到结算
    const checkoutHandle = () => {
        let searchSrc = '';
        const product_name: string[] = [];
        cartList.forEach((item: any) => {
            searchSrc += `,${item.variantId}:${item.quantity}`;
            product_name.push(item.title);
        });
        searchSrc = searchSrc.slice(1);

        try {
            gtm.push({
                event: 'uaEvent',
                event_name: 'checkout',
                button_name: `${textA.win_checkout}_${product_name.join('_')}`,
            });
        } catch (e) {
            console.error(e);
        } finally {
            window.open(saveCookieForShopify(`https://${locale}.ecoflow.com/cart/${searchSrc}?channel=buy_button`, locale), '_blank');
        }
    };

    if (!isClient) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes}>
            <dialog className={`${prefixCls}-icon`} onClick={toggleCartHandle}>
                <IconCart />
                {cartList?.length > 0 && <span className={`${prefixCls}-icon-inventory`}>{totalQuantity}</span>}
            </dialog>
            <dialog className={`${prefixCls}-win`}>
                <div className={`${prefixCls}-header`}>
                    {textA.win_title}
                    <button className={`${prefixCls}-close`} onClick={closeCartHandle}>
                        <IconClose />
                    </button>
                </div>
                <ul className={`${prefixCls}-mainer ${coupon?.ruleType}`}>
                    {cartList?.map((item: any, index: number) => {
                        const hitProduct: CouponHitProductType | null =
                            coupon?.hitProducts?.find((product: CouponHitProductType) => String(product?.variantId) === String(item?.variantId)) || null;
                        return (
                            <li key={`${item.variantId}${index}`} className={`${prefixCls}-item`}>
                                <div className={`${prefixCls}-item-img`}>
                                    <EfImg pc={item.img} />
                                </div>
                                <div className={`${prefixCls}-item-msg`}>
                                    <div className={`${prefixCls}-item-title`}>{item['name']}</div>
                                    <div className={`${prefixCls}-item-price`}>{FormatMoneyLocal(item['discountPrice'], locale)}</div>
                                    <div className={`${prefixCls}-item-tools`}>
                                        <EfBundlesCalculator item={item} defaultQuantity={item.quantity} callback={inventoryChangeCallback} />
                                    </div>
                                    {coupon?.ruleType === 'COUPONS' && hitProduct && (
                                        <div className="cart-coupon">
                                            <div className="coupon-info">
                                                <div className="coupon-code" onClick={() => copyText(item)} data-clipboard-text={coupon.code}>
                                                    {couponIcon()}
                                                    {copyed !== (item?.parentVariantId ? `${item.variantId}_${item?.parentVariantId}` : item?.variantId) && (
                                                        <span className="code-text">{'***'}</span>
                                                    )}
                                                    <span className="code-text">
                                                        {copyed === (item?.parentVariantId ? `${item.variantId}_${item?.parentVariantId}` : item?.variantId)
                                                            ? textA.copied
                                                            : coupon.code?.slice(coupon.code?.length - 4)}
                                                    </span>
                                                    {copyIcon()}
                                                </div>
                                                <span className="coupon-discount">
                                                    {'-'}
                                                    {FormatMoneyLocal(hitProduct.discount, locale)}
                                                </span>
                                            </div>
                                            {renderCountdown}
                                        </div>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
                {cartList?.length > 0 && (
                    <div className={`${prefixCls}-footer`}>
                        {cartList.length > 0 && coupon && coupon.amount && (
                            <>
                                <div className={`${prefixCls}-footer-price subtotal-price`}>
                                    <span className="total">{textA.subtotal}</span>
                                    <span className="price">
                                        {FormatMoneyLocal(
                                            cartList.reduce((prev: number, curObj: any) => {
                                                return prev + (Number(curObj.discountPrice) * 100 * (curObj.quantity ?? 0)) / 100;
                                            }, 0),
                                            locale,
                                        )}
                                    </span>
                                </div>
                                <div className={`${prefixCls}-footer-price discount-price`}>
                                    <span className="total">
                                        {coupon.ruleType === 'COUPONS' ? textA.discount : coupon.ruleType === 'GIFT_CARD' ? textA.gift : ''}
                                    </span>
                                    {coupon.ruleType !== 'GIFT_CARD' && (
                                        <span className="price">
                                            {'-'}
                                            {FormatMoneyLocal(Number(coupon.discountAmount), locale)}
                                        </span>
                                    )}
                                </div>
                                {coupon.ruleType === 'GIFT_CARD' && (
                                    <div className="gift-card">
                                        <div className="gift-info">
                                            <div className="gift-code" onClick={copyTextGift} data-clipboard-text={coupon.code}>
                                                {giftIcon()}
                                                {!copyedGift && <span className="code-text">{'***'}</span>}
                                                <span className="code-text">{copyedGift ? textA.copyed : coupon.code?.slice(coupon.code?.length - 4)}</span>
                                                {copyIcon()}
                                            </div>
                                            <span className="gift-discount">
                                                {'-'}
                                                {FormatMoneyLocal(Number(coupon?.discountAmount), locale)}
                                            </span>
                                        </div>
                                        {renderCountdown}
                                    </div>
                                )}
                            </>
                        )}
                        <div className={`${prefixCls}-footer-price`}>
                            <span>{textA.total}</span>
                            <span>{FormatMoneyLocal(totalPrice - Number(coupon?.discountAmount || 0), locale)}</span>
                        </div>
                        <div className={`${prefixCls}-footer-checkout`} onClick={checkoutHandle}>
                            {textA.win_checkout}
                        </div>
                    </div>
                )}
            </dialog>
        </div>,
        document.body,
    );
};

export default EfShopingCart;
