'use client';
export default function Logo() {
    return (
        <svg width="117" height="13" viewBox="0 0 117 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Logotype_English" clipPath="url(#clip0_33_1778)">
                <path
                    id="Vector"
                    d="M14.0043 2.07752H0V0H14.0043V2.07752ZM13.9842 7.27358H0V5.19151H13.9842V7.27358ZM13.9842 10.3895V12.4664H0V10.3882L13.9842 10.3895Z"
                    fill="black"
                />
                <path
                    id="Vector_2"
                    d="M29.0852 2.09506L24.0934 2.10482C20.7522 2.09961 19.182 3.47638 19.182 6.23382C19.182 8.99126 20.7509 10.3739 24.085 10.3739H31.0843V12.4442L24.0921 12.4553C19.4316 12.4627 17.1013 10.3958 17.1013 6.25464C17.1013 2.11349 19.4154 0.0372788 24.0436 0.026001H31.0843V2.09961L29.0852 2.09506Z"
                    fill="black"
                />
                <path
                    id="Vector_3"
                    d="M41.1417 0.026001C45.8351 0.026001 48.1822 2.09528 48.183 6.23382C48.1839 10.3724 45.8519 12.4462 41.187 12.4553C36.5256 12.4627 34.1951 10.3958 34.1956 6.25464C34.196 2.11349 36.5114 0.0372788 41.1417 0.026001ZM46.0829 6.23252C46.0829 3.47508 44.5192 2.09831 41.1903 2.10352C37.8497 2.09831 36.3054 3.47508 36.3054 6.23252C36.3054 8.98996 37.8477 10.3726 41.1818 10.3726C44.516 10.3726 46.0829 8.99451 46.0829 6.23252Z"
                    fill="black"
                />
                <path
                    id="Vector_4"
                    d="M114.597 0.0170395V10.3675H110.596V0.0222438V0.0170395H108.593V10.3688H104.593V0.00597765V0.00012207H102.593V12.4652H114.604C116.103 12.4652 116.597 11.6688 116.597 10.4027V0.0170395H114.597Z"
                    fill="black"
                />
                <path
                    id="Vector_5"
                    d="M82.3973 10.374H71.8891C71.4939 10.374 71.115 10.2164 70.8356 9.93572C70.5562 9.65507 70.3992 9.27444 70.3992 8.87755V0.00402832H68.3982V9.43189C68.3991 10.2345 68.7173 11.004 69.2829 11.571C69.8485 12.138 70.6151 12.4561 71.4142 12.4555H82.3973V10.374Z"
                    fill="black"
                />
                <path
                    id="Vector_6"
                    d="M92.4281 0.026001C97.1214 0.026001 99.4686 2.09528 99.4694 6.23382C99.4703 10.3724 97.1383 12.4462 92.4734 12.4553C87.812 12.4627 85.4815 10.3958 85.4819 6.25464C85.4824 2.11349 87.7977 0.0372788 92.4281 0.026001ZM97.3693 6.23252C97.3693 3.47508 95.8056 2.09831 92.4766 2.10352C89.136 2.09831 87.5917 3.47508 87.5917 6.23252C87.5917 8.98996 89.1341 10.3726 92.4682 10.3726C95.8023 10.3726 97.3693 8.99451 97.3693 6.23252Z"
                    fill="black"
                />
                <path id="Vector_7" d="M65.301 0.026001H51.2974V2.09961H65.301V0.026001Z" fill="black" />
                <path id="Vector_8" d="M65.2835 7.2828H53.2964V9.86456V12.4444H51.2974V7.2828V5.20789H65.2835V7.2828Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_33_1778">
                    <rect width="116.6" height="12.4651" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
