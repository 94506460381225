export const prefixkey = 'popup_close_time_';

function timeDiff(prevTime: number, afterTime: number, hour: number) {
    const timeDiff = Math.abs(afterTime - prevTime);
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    if (hoursDiff > hour) {
        return true;
    }

    return false;
}

export function clearExpiredRecord() {
    const keys = Object.keys(localStorage);
    const recordValues = keys
        .filter((key) => key.startsWith(prefixkey))
        .map((key) => {
            const k = localStorage.getItem(key);
            if (k) {
                const timeNumber = parseInt(k, 10);
                const currentTime = Date.now();

                if (timeDiff(currentTime, timeNumber, 24)) {
                    return key;
                }

                return '';
            }
        })
        .filter((key) => !!key);

    recordValues.forEach((item) => {
        item && localStorage.removeItem(item);
    });
}

export function recordTime(locale: string) {
    const currentTime = Date.now();
    locale && window.localStorage.setItem(`${prefixkey}${locale}`, currentTime.toString());
}

export function checkIsWithinTime(locale: string) {
    const key = `${prefixkey}${locale}`;
    const value = localStorage.getItem(key);
    if (!value) return false;

    const timeNumber = parseInt(value, 10);
    const currentTime = Date.now();

    return !timeDiff(currentTime, timeNumber, 24);
}
