import EditorLink from 'lowcode/components/EditorLink';
import { useCountDown } from 'ahooks';
import { useEffect, useState } from 'react';
import css from './index.module.scss';

export default function TopBarCountdown({
    startTime,
    endTime,
    href,
    title,
    sub_title,
    time_front_tips,
    time_front_tips2,
    time_day,
    time_hour,
    time_minute,
    time_second,
    debugTime,
}: {
    debugTime?: number;
    startTime?: number;
    endTime?: number;
    href: string;
    title?: string;
    sub_title?: string;
    time_front_tips?: string;
    time_front_tips2?: string;
    time_day?: string;
    time_hour?: string;
    time_minute?: string;
    time_second?: string;
}) {
    const nowTime = debugTime || Date.now();
    // 还没开始,当前时间小于开始时间
    if (startTime && nowTime < startTime) {
        return null;
    }
    // 已经结束,当前时间大于结束时间
    if (endTime && nowTime > endTime) {
        return null;
    }
    const [_countdown, formattedRes] = useCountDown({ targetDate: endTime });
    const [isClient, setIsClient] = useState(false); // 不加这个的话,会出现水合

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <EditorLink href={href} className={css.TopBarCountdown}>
            <div className="TopBarCountdown-in">
                <div className="TopBarCountdown-l">
                    <div className="TopBarCountdown-title">{title}</div>
                    <div className="TopBarCountdown-sub_title">{sub_title}</div>
                </div>
                {isClient && (
                    <div className="TopBarCountdown-r">
                        <span className="TopBarCountdown-front_tips">
                            <span className="TopBarCountdown-front_tips_pc">{time_front_tips}</span>
                            <span className="TopBarCountdown-front_tips_h5">{time_front_tips2 || time_front_tips}</span>
                        </span>
                        <span className="TopBarCountdown-text1">{formattedRes.days}</span>
                        <span className="TopBarCountdown-text2">{time_day}</span>
                        <span className="TopBarCountdown-text1">{String(formattedRes.hours).padStart(2, '0')}</span>
                        <span className="TopBarCountdown-text2">{time_hour}</span>
                        <span className="TopBarCountdown-text1">{String(formattedRes.minutes).padStart(2, '0')}</span>
                        <span className="TopBarCountdown-text2">{time_minute}</span>
                        <span className="TopBarCountdown-text1">{String(formattedRes.seconds).padStart(2, '0')}</span>
                        <span className="TopBarCountdown-text2">{time_second}</span>
                    </div>
                )}
            </div>
        </EditorLink>
    );
}
