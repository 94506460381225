'use client';

export const langText: any = {
    jp: {
        title: '【期間限定&数量限定 超早割35%OFF】',
        sub_title: 'DELTA Pro 3 発売記念キャンペーン開催中',
        time_front_tips: '終了まで:',
        time_front_tips2: '終了まで',
        time_day: '日',
        time_hour: '時間',
        time_minute: '分',
        time_second: '秒',
    },
};

export const endTimeObj: any = {
    jp: {
        endTime: new Date('2024/07/31 23:00:00 GMT+8').getTime(),
    },
};
