'use client';

export const langText: any = {
    us: {
        win_title: 'Shopping Cart',
        win_checkout: 'Checkout',
        countdown: 'Expires in ',
        copied: 'Copied',
        subtotal: 'Subtotal',
        discount: 'Discount',
        gift: 'Gift Card',
        total: 'Total',
    },
    jp: {
        win_title: 'ショッピングカート',
        win_checkout: 'ご購入手続きへ',
        countdown: '失効まで ',
        copied: 'コピーしました',
        subtotal: '小計',
        discount: '割引き',
        gift: 'ギフトカード',
        total: '合計',
    },
    ca: {
        win_title: 'Shopping Cart',
        win_checkout: 'Checkout',
        countdown: 'Expires in ',
        copied: 'Copied',
        subtotal: 'Subtotal',
        discount: 'Discount',
        gift: 'Gift Card',
        total: 'Total',
    },
    eu: {
        win_title: 'Shopping Cart',
        win_checkout: 'Checkout',
        countdown: 'Expires in ',
        copied: 'Copied',
        subtotal: 'Subtotal',
        discount: 'Discount',
        gift: 'Gift Card',
        total: 'Total',
    },
    za: {
        win_title: 'Shopping Cart',
        win_checkout: 'Checkout',
        countdown: 'Expires in ',
        copied: 'Copied',
        subtotal: 'Subtotal',
        discount: 'Discount',
        gift: 'Gift Card',
        total: 'Total',
    },
    de: {
        win_title: 'Warenkorb',
        win_checkout: 'Zur Kasse',
        countdown: 'Verfällt in ',
        copied: 'kopiert',
        subtotal: 'Zwischensumme',
        discount: 'Rabatt',
        gift: 'Geschenkgutschein',
        total: 'Gesamtsumme',
    },
    au: {
        win_title: 'Shopping Cart',
        win_checkout: 'Checkout',
        countdown: 'Expires in ',
        copied: 'Copied',
        subtotal: 'Subtotal',
        discount: 'Discount',
        gift: 'Gift Card',
        total: 'Total',
    },
    fr: {
        win_title: 'Panier',
        win_checkout: 'Paiement',
        countdown: 'Temps restant ',
        copied: 'copié',
        subtotal: 'Sous-total',
        discount: 'Réduction',
        gift: 'Carte-cadeau',
        total: 'Total',
    },
    es: {
        win_title: 'Carrito de compras',
        win_checkout: 'Verificar',
        countdown: 'Tiempo restante ',
        copied: 'copiado',
        subtotal: 'Subtotal',
        discount: 'Descuento',
        gift: 'Tarjeta regalo',
        total: 'Total',
    },
    it: {
        win_title: 'Carrello della spesa',
        win_checkout: 'Checkout',
        countdown: 'Tempo rimasto ',
        copied: 'copiato',
        subtotal: 'Totale parziale',
        discount: 'Sconto',
        gift: 'Buono regalo',
        total: 'Totale',
    },
    kr: {
        win_title: '장바구니',
        win_checkout: '결제',
        countdown: '남은 시간 ',
        copied: '복사됨',
        subtotal: '소계',
        discount: '할인',
        gift: '기프트 카드',
        total: '총계',
    },
};
