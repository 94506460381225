'use client';
export default function (props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 8" {...props}>
            <path
                d="M7 5.38L2.237.66A.872.872 0 0 0 1.6.4c-.25 0-.463.087-.638.26S.7 1.044.7 1.292s.087.458.263.632L6.4 7.312c.4.396.813.384 1.238-.037l5.4-5.351c.175-.174.262-.384.262-.632s-.087-.458-.263-.632C12.864.487 12.65.4 12.4.4s-.462.087-.637.26L7 5.38z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}

export const BottomArrowIcon = (props: any) => (
    <svg width="16px" height="36px" viewBox="0 0 16 36" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <linearGradient x1="0%" y1="63.9915842%" x2="113.117403%" y2="63.9915842%" id="linearGradient-1">
                <stop stopColor="#CAEEFF" offset="0%"></stop>
                <stop stopColor="#3066FF" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="终稿0323" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="M端/wave-2上" transform="translate(-155.000000, -4114.000000)" fill="url(#linearGradient-1)">
                <g id="编组-14" transform="translate(155.000000, 4046.045694)">
                    <g id="编组-13" transform="translate(0.308633, 52.900703)">
                        <path
                            d="M9.29136705,15.1731107 L9.291,37.2191107 L15.219696,37.211817 L7.62698948,50.2788165 L-9.41382855e-05,37.2313691 L6.091,37.2231107 L6.09136705,15.1731107 L9.29136705,15.1731107 Z"
                            id="形状结合备份-2"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
