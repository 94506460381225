'use client';

import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';

import BhStat from 'lowcode/BhStat';
import { getPageSource } from 'lowcode/BhStat/getPageSource';
import { getRealPathname } from 'lowcode/BhStat/getRealPathname';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';

export default function NextLink({
    children,
    href,
    newWin,
    selfWin,
    ariaLabel = 'Ecoflow',
    as,
    dataLayer = null,
    className,
    stopPropagation = false,
    ...rest
}: any) {
    const { locale } = getLocal();

    const hasLocale = new RegExp(`^/${locale}/`, 'i');
    let isHttpUrl = false;
    if (typeof href === 'string') {
        isHttpUrl = /^http/i.test(href?.trim());
    }

    // 判断是否在新窗口打开：a) http 开头的绝对地址且不能包含www.ecoflow.com；b) 有 newWin 属性的
    const isExternalUrl = (isHttpUrl && href?.indexOf('www.ecoflow.com') === -1) || typeof newWin !== 'undefined';
    const aProps: any = {
        target: '_blank',
    };
    let newHref = href; //官网链接
    let newHrefExternal = href; //其它链接

    // 不是http链接且不带有local,补上locale
    if (!isHttpUrl && !hasLocale.test(href)) {
        newHref = `/${locale}${newHref}`;
    }

    const handleGTM = (params: any) => {
        if (Array.isArray(params)) {
            params.forEach((v) => gtm.push(v));
        } else if (params) {
            gtm.push(params);
            try {
                const eventObj = {
                    ...params,
                };
                const pagePath = getRealPathname(location.pathname);
                eventObj.pagePath = pagePath;
                eventObj.pageSource = getPageSource();
                BhStat.push(eventObj);
            } catch (error: any) {}
        }
    };

    const props = {
        href: newHref,
        ...rest,
    };

    if (typeof as !== 'undefined') {
        props.as = hasLocale.test(as) ? as : `/${locale}${as}`;
        newHrefExternal = props.as;
    }

    if (isExternalUrl) {
        aProps.onClick = (e: any) => {
            handleGTM(dataLayer);
        };
    } else if (isExternalUrl) {
    }
    if (!href) {
        return children;
    }
    return isExternalUrl ? (
        <a className={className} href={newHrefExternal} aria-label={props.ariaLabel} {...aProps}>
            {children}
        </a>
    ) : (
        <a
            {...props}
            className={className}
            target={props.target || '_self'}
            aria-label={ariaLabel}
            onClick={(e) => {
                if (stopPropagation) {
                    e.stopPropagation();
                }
                saveCookieForShopify(href, locale);
                handleGTM(dataLayer);
            }}
        >
            {children}
        </a>
    );
}
