export default function (props: any) {
    return (
        <svg width="28px" height="29px" viewBox="0 0 28 29" version="1.1" {...props}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="商城-弹窗" transform="translate(-497.000000, -1724.000000)" fill="currentColor">
                    <path
                        d="M511,1724.5 C518.731986,1724.5 525,1730.76801 525,1738.5 C525,1746.23199 518.731986,1752.5 511,1752.5 C503.268014,1752.5 497,1746.23199 497,1738.5 C497,1730.76801 503.268014,1724.5 511,1724.5 Z M513.514415,1732 L511.288117,1732 L511.288117,1740.88418 C511.288117,1741.90234 510.490156,1742.72881 509.507852,1742.72881 C508.524259,1742.72881 507.726298,1741.90234 507.726298,1740.88418 C507.726298,1739.86475 508.524259,1739.03955 509.507852,1739.03955 C509.721845,1739.03955 509.934548,1739.07891 510.134361,1739.15635 L510.134361,1739.15635 L510.134361,1736.7874 C509.925524,1736.75186 509.711532,1736.73281 509.491093,1736.73281 C507.28671,1736.73281 505.5,1738.58252 505.5,1740.86641 C505.5,1743.15029 507.28671,1745 509.491093,1745 C511.695476,1745 513.482187,1743.15029 513.482187,1740.86641 L513.482187,1740.86641 L513.482187,1736.39385 C514.348471,1737.03496 515.401676,1737.38154 516.484531,1737.38154 L516.484531,1737.38154 L516.5,1737.38027 L516.5,1735.09131 C514.851225,1735.09131 513.514415,1733.70752 513.514415,1732 L513.514415,1732 Z"
                        id="tiktok"
                    />
                </g>
            </g>
        </svg>
    );
}
