// 计算总价
export const getTotalPrice = (items: any, priceName = 'discountPrice') => {
    let totalPrice = 0;
    let totalQuantity = 0;
    items.forEach((item: any) => {
        totalPrice += (item[priceName] || 0) * 100 * item.quantity;
        totalQuantity += item.quantity;
    });

    return {
        totalPrice: totalPrice / 100,
        totalQuantity,
    } as any;
};

// 识别新加的值在哪个下标
export const getIndexItemForAdd = (items: any, item: any) => {
    let currentIndex = -1;
    items.forEach((data: any, index: number) => {
        if (item.variantId === data.variantId) {
            currentIndex = index;
        }
    });

    return currentIndex;
};
