export const getLogoUrl = (locale: string | undefined) => {
    const linkMap: any = {};
    // @ts-ignore
    return linkMap[locale] || '/';
};

export const getNavListMap = (locale: string) => {
    const HAS_STORE_SITE = ['us', 'ca', 'au', 'cn', 'jp', 'de', 'it', 'es'];
    const HAS_COMMUNITY_SITE = ['us', 'ca', 'eu', 'uk', 'au', 'za', 'de', 'fr', 'it', 'es'];
    const HAS_CSR_SITE = ['de', 'fr', 'it', 'es', 'cn', 'za', 'au'];
    const HAS_ECOCREDITS_SITE = ['us', 'ca', 'za', 'uk', 'fr', 'it', 'es', 'jp', 'au', 'kr'];
    const HAS_REFERRAL_SITE = ['us', 'ca', 'za', 'uk', 'fr', 'it', 'es', 'au', 'kr'];

    const navListMap: any = {
        common: [
            HAS_CSR_SITE.includes(locale) && {
                icons: 'EFCsr',
                name: `navigation.explore.site_nav_explore_csr`,
                href: `/corporate-social-responsibility`,
            },
            {
                icons: 'EFNews',
                name: `navigation.explore.site_nav_explore_newsroom`,
                href: '/news',
            },
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
            HAS_STORE_SITE.includes(locale) && {
                icons: 'EFWhere',
                name: `navigation.explore.site_nav_explore_where_to_buy`,
                href: '/where-to-buy',
            },
            HAS_COMMUNITY_SITE.includes(locale) && {
                icons: 'EFCommunity',
                name: `navigation.explore.site_nav_explore_community`,
                href: '/community',
            },
            HAS_ECOCREDITS_SITE.includes(locale) && {
                icons: 'Ecocredits',
                name: `navigation.explore.site_nav_explore_ecocredits`,
                href: '/ecocredits',
            },
            HAS_REFERRAL_SITE.includes(locale) && {
                icons: 'Referral',
                name: `navigation.explore.site_nav_explore_referral`,
                href: locale === 'au' ? '/referral' : `https://${locale}.ecoflow.com/pages/refer-a-friend`,
            },
        ],
        us: [
            {
                icons: 'EFCsr',
                name: `navigation.explore.site_nav_explore_csr`,
                href: `/corporate-social-responsibility`,
            },
            {
                icons: 'EFNews',
                name: `navigation.explore.site_nav_explore_newsroom`,
                href: '/news',
            },
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
            {
                icons: 'EFWhere',
                name: `navigation.explore.site_nav_explore_where_to_buy`,
                href: '/where-to-buy',
            },
            {
                icons: 'EFCommunity',
                name: `navigation.explore.site_nav_explore_community`,
                href: '/community',
            },
            {
                icons: 'Ecocredits',
                name: `navigation.explore.site_nav_explore_ecocredits`,
                href: '/ecocredits',
            },
            {
                icons: 'Referral',
                name: `navigation.explore.site_nav_explore_referral`,
                href: 'https://us.ecoflow.com/pages/refer-a-friend',
            },
            {
                icons: 'Refurbished',
                name: `navigation.explore.site_nav_explore_refurbished`,
                href: '/refurbished',
            },
        ],
        ca: [
            {
                icons: 'EFCsr',
                name: `navigation.explore.site_nav_explore_csr`,
                href: `/corporate-social-responsibility`,
            },
            {
                icons: 'EFNews',
                name: `navigation.explore.site_nav_explore_newsroom`,
                href: '/news',
            },
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
            {
                icons: 'EFWhere',
                name: `navigation.explore.site_nav_explore_where_to_buy`,
                href: '/where-to-buy',
            },
            {
                icons: 'EFCommunity',
                name: `navigation.explore.site_nav_explore_community`,
                href: '/community',
            },
            {
                icons: 'Ecocredits',
                name: `navigation.explore.site_nav_explore_ecocredits`,
                href: '/ecocredits',
            },
            {
                icons: 'Referral',
                name: `navigation.explore.site_nav_explore_referral`,
                href: 'https://ca.ecoflow.com/pages/refer-a-friend',
            },
        ],
        sa: [
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
        ],
        jp: [
            {
                icons: 'SaveElectric',
                name: `navigation.explore.site_nav_explore_save_electric`,
                href: '/save-on-electric-bills',
            },
            {
                icons: 'EFCsr',
                name: `navigation.explore.site_nav_explore_csr`,
                href: `/support-for-disaster-prevention`,
            },
            {
                icons: 'EFBlog',
                name: `navigation.explore.site_nav_explore_blog`,
                href: `${process.env.NEXT_PUBLIC_BLOG_HOST}/${locale}`,
                target: '_blank',
            },
            {
                icons: 'EFNews',
                name: `navigation.explore.site_nav_explore_newsroom`,
                href: 'https://blog.ecoflow.com/jp/category/newsroom/',
            },
            {
                icons: 'EFWhere',
                name: `navigation.explore.site_nav_explore_where_to_buy`,
                href: '/where-to-buy',
            },
            {
                icons: 'EFBrandStory',
                name: `navigation.explore.site_nav_explore_brand_story`,
                href: '/brand-story',
            },
            {
                icons: 'EFCase',
                name: 'navigation.explore.site_nav_explore_case',
                href: '/solutions/casestudy',
            },
            {
                icons: 'Ecocredits',
                name: `navigation.explore.site_nav_explore_ecocredits`,
                href: '/ecocredits',
            },
        ],
        cn: [
            HAS_CSR_SITE.includes(locale) && {
                icons: 'EFCsr',
                name: `navigation.explore.site_nav_explore_csr`,
                href: `/corporate-social-responsibility`,
            },
            {
                icons: 'EFSocialRCM',
                name: `navigation.explore.site_nav_explore_SocialRCM`,
                href: 'https://ecoflow.jobs.feishu.cn/index',
            },
            {
                icons: 'EFSchoolRCM',
                name: `navigation.explore.site_nav_explore_SchoolRCM`,
                href: 'https://ecoflow.jobs.feishu.cn/602892',
            },
            {
                icons: 'EFBlog',
                name: `navigation.explore.site_nav_explore_blog`,
                href: `${process.env.NEXT_PUBLIC_BLOG_HOST}/${locale}`,
                target: '_blank',
            },
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
            {
                icons: 'EFWhere',
                name: `navigation.explore.site_nav_explore_where_to_buy`,
                href: '/where-to-buy',
            },
        ],
        za: [
            HAS_CSR_SITE.includes(locale) && {
                icons: 'EFCsr',
                name: `navigation.explore.site_nav_explore_csr`,
                href: `/corporate-social-responsibility`,
            },
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
            {
                icons: 'EFCommunity',
                name: `navigation.explore.site_nav_explore_community`,
                href: '/community',
            },
            {
                icons: 'Ecocredits',
                name: `navigation.explore.site_nav_explore_ecocredits`,
                href: '/ecocredits',
            },
            {
                icons: 'Referral',
                name: `navigation.explore.site_nav_explore_referral`,
                href: 'https://za.ecoflow.com/pages/refer-a-friend',
            },
        ],
        cis: [
            {
                icons: 'EFBlog',
                name: `navigation.explore.site_nav_explore_blog`,
                href: `https://ecoflow-russia.com/blog`, // ru站博客地址
                target: '_blank',
            },
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
        ],
        tw: [
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
        ],
        kr: [
            {
                icons: 'EFAboutUs',
                name: `navigation.explore.site_nav_explore_about_us`,
                href: '/about-us',
            },
            {
                icons: 'Ecocredits',
                name: `navigation.explore.site_nav_explore_ecocredits`,
                href: '/ecocredits',
            },
            {
                icons: 'Referral',
                name: `navigation.explore.site_nav_explore_referral`,
                href: '/referral',
            },
        ],
    };

    return navListMap[locale] || navListMap.common;
};
