export default function (props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="icon24/top">
                <path
                    id="Vector"
                    d="M12 20.5V6M12 6L6.71191 11.4306M12 6L17.2119 11.4306"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path id="Line 3" d="M6 4H18" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            </g>
        </svg>
    );
}
