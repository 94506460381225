'use client';
import dynamic from 'next/dynamic';
import { useState, useEffect } from 'react';
import { getIsPadWidth } from '@elem/utils/devices';
import css from './index.module.scss';
import { localValueMap } from '@/utils/locals';
import EFEarth from '@/components/UI/svg/Footer/Icon/EFEarth';
import getLocal from '@/app/utils/getLocal';
import { useStore } from '@/utils/useStore';

// 两种场景可以使用 lazy 或 dynamic 1.非首屏渲染的模块（包含条件渲染）2.拆包大小并且做seo优化可以使用 ssr: true
const EFRegion: any = dynamic(() => import('@/components/UI/EFRegion'), { ssr: false });

export default function I18n() {
    const router = getLocal();
    const { locale, route, query } = router;
    const [languageRegion, setLanguageRegion] = useState('');
    const [visible, setVisible] = useState(false);
    const [destroy, setDestroy] = useState(false);
    const store = useStore();

    if (route.includes('[')) {
        Object.entries(query)?.map((ele) => {
            const key = ele[0];
            const val = ele[1];
            route?.replace(`[${key}]`, val);
        });
    }

    const gotoRegion = () => {
        store.setStore({ currentPath: router.asPath });
        router.push({ pathname: `/${locale}/region` });
    };

    const handleClick = () => {
        const isMobile = getIsPadWidth(); //是否是移动端
        if (isMobile) {
            gotoRegion();
        } else {
            setDestroy(false);
            setVisible(true);
        }
    };

    useEffect(() => {
        const curLocal = localValueMap[locale] || localValueMap['en'];
        if (Object.keys(curLocal).length !== 0) {
            // @ts-ignore
            setLanguageRegion(`${curLocal?.regionText}(${curLocal?.languageText})`);
        }
    }, [locale]);

    return (
        <div className={css.lang_selector}>
            <div className="lang_item_current" aria-label={languageRegion} onClick={handleClick}>
                <EFEarth className="earth" />
                <span className="lang_text">{languageRegion || ''}</span>
            </div>
            {!destroy && visible && <EFRegion visible={visible} onClose={() => setVisible(false)} onDestroy={() => setDestroy(true)} />}
        </div>
    );
}
