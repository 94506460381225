import css from './index.module.scss';
import EfImg from 'elem/ui/ef-img';
import EfLink from 'elem/ui/ef-link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import gtm from '@/utils/gtm';

export default function PDFActivity({ top }: any) {
    const { locale, query } = useRouter();
    const now = query?._timer ? new Date(query?._timer).getTime() : new Date().getTime();
    const times = {
        us: { start: '2024/09/23 15:00:00 GMT+8', end: '2024/10/08 15:00:00 GMT+8' },
    };
    const IS_ACTIVE = now >= new Date(times[locale]?.start).getTime() && now < new Date(times[locale]?.end).getTime();
    const IS_PRE = now < new Date(times[locale]?.start).getTime();

    const localImgs = {
        us: {
            a: {
                pc: 'https://websiteoss.ecoflow.com/upload/image/1726196152030/pc.jpg',
                pad: 'https://websiteoss.ecoflow.com/upload/image/1726196361075/pad.jpg',
                h5: 'https://websiteoss.ecoflow.com/upload/image/1726196192987/m.jpg',
            },
            b: {
                pc: 'https://websiteoss.ecoflow.com/upload/image/1726196518449/1_pc.jpg',
                pad: 'https://websiteoss.ecoflow.com/upload/image/1726196540401/1_pad.jpg',
                h5: 'https://websiteoss.ecoflow.com/upload/image/1726196564829/1_h5.jpg',
                link: 'https://us.ecoflow.com/pages/prime-day-fall',
            },
        },
    };

    const img = (localImgs[locale] || localImgs['us'])[IS_ACTIVE ? 'b' : 'a'];

    useEffect(() => {
        gtm.push({
            event: 'uaEvent',
            event_name: 'view_banner',
            banner_name: 'Early Prime Big Deal Days: Up to $2,899 Off',
            position: 'activityBanner_1',
        });
    }, []);

    return (
        <div
            className={`${css['pdf-activity']} pdf-activity${IS_ACTIVE ? ' activity-bar' : ''}`}
            style={IS_ACTIVE ? { top: top + 'px' } : !IS_PRE ? { display: 'none' } : {}}
        >
            {IS_ACTIVE && (
                <EfLink
                    href={img?.link}
                    className="pdf-lnik"
                    dataLayer={{
                        event: 'uaEvent',
                        event_name: 'select_banner',
                        banner_name: 'Early Prime Big Deal Days: Up to $2,899 Off',
                        position: 'activityBanner_1',
                        button_name: 'none',
                    }}
                >
                    <EfImg pc={img.pc} pad={img.pad} h5={img.h5} />
                </EfLink>
            )}
            {IS_PRE && <EfImg pc={img.pc} pad={img.pad} h5={img.h5} />}
        </div>
    );
}
