import classNames from 'classnames';
import React from 'react';
import './index.scss';

interface EfProgressCircleProps {
    /**
     * 样式名
     */
    className?: string;
    /**
     * 进度-0-100
     */
    process: number;
    /**
     * 进度条显示文案
     */
    processText?: number | string;
    /**
     * 进度条背景色(已达到)
     */
    nowCircleColor?: string;
    /**
     * 进度条背景色(未达到)
     */
    lastCircleColor?: string;
    /**
     * 中间区域背景色
     */
    transparentColor?: string;
}

/**
 * 维护:谢保林
 *
 * 圆形进度条
 */
const EfProgressCircle: React.FC<EfProgressCircleProps> = ({
    process = 0,
    processText,
    className,
    nowCircleColor = '#fff',
    lastCircleColor = '#3F68E0',
    transparentColor = '#3F68E0',
}) => {
    const prefixCls = `ef-progress-circle`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
    });
    const nowProcess = `${process}%`;
    const nowProcess2 = `${process + 0.1}%`;
    const lastProcess = `${100 - process}%`;
    const itemStyle: any = {
        '--ef-progress-circle-nowCircleColor': nowCircleColor,
        '--ef-progress-circle-lastCircleColor': lastCircleColor,
        '--ef-progress-circle-transparentColor': transparentColor,
        //'--ef-progress-circle-background': `conic-gradient(${nowCircleColor} ${nowProcess}, ${lastCircleColor} ${lastProcess})`,
        '--ef-progress-circle-background': `conic-gradient(${nowCircleColor} ${nowProcess}, ${lastCircleColor} ${nowProcess2}, ${lastCircleColor} ${lastProcess})`,
    };
    const centerText = processText || nowProcess;

    return <div className={classes} style={itemStyle} data-progress={centerText}></div>;
};

export default EfProgressCircle;
