export default function (props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z" fill="white" />
            <path
                d="M9.10822 5.14956L6.39322 7.91456L4.79822 6.71956C4.74538 6.68042 4.68535 6.65209 4.62156 6.63619C4.55776 6.62029 4.49146 6.61712 4.42644 6.62687C4.36142 6.63663 4.29896 6.65911 4.24264 6.69303C4.18632 6.72695 4.13724 6.77165 4.09822 6.82456C4.05908 6.87739 4.03075 6.93742 4.01485 7.00122C3.99895 7.06501 3.99578 7.13132 4.00553 7.19634C4.01528 7.26136 4.03777 7.32381 4.07169 7.38013C4.10561 7.43646 4.1503 7.48553 4.20322 7.52456L6.17322 8.97956C6.26039 9.04269 6.3656 9.076 6.47322 9.07456C6.53902 9.07494 6.60425 9.06233 6.66517 9.03744C6.72609 9.01256 6.7815 8.9759 6.82822 8.92956L9.82822 5.85456C9.87451 5.80761 9.9111 5.752 9.9359 5.69092C9.9607 5.62983 9.97323 5.56445 9.97276 5.49852C9.9723 5.43259 9.95885 5.3674 9.93319 5.30667C9.90754 5.24593 9.87016 5.19085 9.82322 5.14456C9.77627 5.09827 9.72066 5.06168 9.65958 5.03688C9.59849 5.01207 9.53311 4.99955 9.46718 5.00001C9.40125 5.00048 9.33606 5.01392 9.27533 5.03958C9.21459 5.06524 9.15951 5.10261 9.11322 5.14956H9.10822Z"
                fill="black"
            />
        </svg>
    );
}
