import styled from './index.module.scss';
import { recordTime } from './time';
import Link from '@/components/utils/Link2';
import { EFTooltipCheck, EFTooltipClose } from '@/components/UI/svg/My';
import gtm from '@/utils/gtm';
import getLocal from '@/app/utils/getLocal';

const useLang = () => {
    const { locale } = getLocal();
    const langText: Record<string, { [key: string]: string }> = {
        au: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        us: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        ca: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        cis: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        cn: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        de: {
            desc: 'Sie erhalten 500 EcoCredits im Wert von 25 €',
            button: 'Jetzt registrieren',
            register_tip: 'Bereits Mitglied? ',
            login: 'Einloggen',
        },
        en: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        es: {
            desc: 'Obtén 500 EcoCredits, con un valor de 25 €',
            button: 'Únete ahora',
            register_tip: '¿Ya tienes una cuenta? ',
            login: 'Inicia sesión',
        },
        eu: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        fr: {
            desc: "Obtenez 500 EcoCredits d'une valeur de 25 €",
            button: 'Rejoignez-nous maintenant',
            register_tip: 'Vous avez déjà un compte? ',
            login: 'Se connecter',
        },
        hk: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        it: {
            desc: 'Ricevi 500 EcoCredits del valore di 25 €',
            button: 'Unisciti ora',
            register_tip: 'Hai già un account? ',
            login: 'Accedi',
        },
        jp: {
            desc: '500 エコポイント をゲット',
            button: '今すぐ登録する',
            register_tip: 'すでにアカウントをお持ちですか？ ',
            login: 'ログイン',
        },
        kr: {
            desc: '500 에코포인트(₩35,000 상당)를 받으세요',
            button: '지금 가입하기',
            register_tip: '기존 사용자이신가요? ',
            login: '로그인',
        },
        sa: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        tw: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        uk: {
            desc: 'Get 500 EcoCredits, Worth £25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        za: {
            desc: 'Get 500 EcoCredits, Worth R500',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    };
    const copies = langText[locale] || langText['us'];

    return {
        t: (text: string) => copies[text] || text,
    };
};

const PopoverPanel = ({ showClose = true, closePopover }: { showClose: boolean; closePopover?: () => void }) => {
    const { locale } = getLocal();
    const { t } = useLang();

    return (
        <div
            className={styled.popover}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {showClose && (
                <div
                    className="close-buuton"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        recordTime(locale);
                        closePopover && closePopover();
                        gtm.push({
                            event: 'uaEvent',
                            event_name: 'floating_close',
                            button_name: 'Close',
                            type: 'Authentication',
                        });
                    }}
                >
                    <EFTooltipClose />
                </div>
            )}
            <div className="desc-content">
                <EFTooltipCheck />
                <div className="text">{t('desc')}</div>
            </div>
            <div
                className="button"
                onClick={(e: any) => {
                    e.stopPropagation();
                    gtm.push({
                        event: 'uaEvent',
                        event_name: 'authentication_button',
                        type: 'Redirect',
                        button_name: t('button'),
                        url: `https://www.ecoflow.com/${locale}/register?redirect=${encodeURIComponent(window.location.href)}`,
                    });
                    window.location.href = `${window.location.origin}/${locale}/register?redirect=${encodeURIComponent(window.location.href)}`;
                }}
            >
                {t('button')}
            </div>
            <div className="tip">
                {t('register_tip')}
                <Link
                    className="text-link"
                    href={`${window.location.origin}/${locale}/login?redirect=${encodeURIComponent(window.location.href)}`}
                    dataLayer={{
                        event: 'uaEvent',
                        event_name: 'authentication_button',
                        type: 'Redirect',
                        button_name: t('login'),
                        url: `https://www.ecoflow.com/${locale}/login?redirect=${encodeURIComponent(window.location.href)}`,
                    }}
                >
                    {t('login')}
                </Link>
            </div>
        </div>
    );
};

export default PopoverPanel;
