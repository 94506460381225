export function EFNoAddress(props: any) {
    return (
        <svg width="58" height="56" viewBox="0 0 58 56" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path fill="#E1E4E7" fillRule="nonzero" d="M9.667 36h38.666L58 56H0z" />
                <path stroke="#FFF" d="m13.062 39.5-5.295 12h42.475l-5.135-12z" />
                <path
                    d="M29 47C17.667 33.351 12 23.38 12 17.089 12 7.65 19.611 0 29 0s17 7.651 17 17.089C46 23.38 40.333 33.35 29 47zm0-25.639c2.347 0 4.25-1.913 4.25-4.272 0-2.36-1.903-4.272-4.25-4.272s-4.25 1.912-4.25 4.272c0 2.36 1.903 4.272 4.25 4.272z"
                    fill="#D4D7D9"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
}

export function EFIconMore(props: any) {
    return (
        <svg width="12" height="12" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.243 9.692 9.485 5.45 5.243 1.207"
                stroke="#2673FF"
                strokeWidth="1.2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function EFAkIn(props: any) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" strokeWidth=".2" fill="currentColor" fillRule="nonzero">
                <path d="M15.031 8.001a.616.616 0 0 0-.019-.163.795.795 0 0 0-.03-.158l-.012-.03a.488.488 0 0 0-.024-.064C13.65 4.75 10.858 2.844 8 2.844c-2.857 0-5.649 1.904-6.94 4.725a.47.47 0 0 0-.033.086.813.813 0 0 0-.01.023c-.025.075-.026.127-.023.114a1.916 1.916 0 0 0-.023.163.406.406 0 0 0 0 .093s.01.095.016.114a.785.785 0 0 0 .016.102.47.47 0 0 0 .029.09.544.544 0 0 0 .022.059C2.352 11.25 5.144 13.155 8 13.155c2.858 0 5.65-1.905 6.932-4.712a.494.494 0 0 0 .042-.101.46.46 0 0 0 .035-.148c.008-.045.022-.113.022-.194zm-.947.035-.002.007-.006.02C12.922 10.55 10.486 12.22 8 12.22c-2.48 0-4.912-1.664-6.077-4.16l-.008-.025c0-.008-.002-.015-.003-.02l-.002-.018V7.99a.331.331 0 0 0 .006-.032.948.948 0 0 0 .005-.019C3.076 5.451 5.514 3.781 8 3.781c2.487 0 4.925 1.671 6.075 4.156l.004.012.004.013a.512.512 0 0 0 .007.046l-.006.028z" />
                <path d="M8 5.656A2.346 2.346 0 0 0 5.656 8 2.346 2.346 0 0 0 8 10.344 2.346 2.346 0 0 0 10.344 8 2.346 2.346 0 0 0 8 5.656zm0 3.75c-.775 0-1.406-.63-1.406-1.406 0-.775.63-1.406 1.406-1.406.775 0 1.406.63 1.406 1.406 0 .775-.63 1.406-1.406 1.406z" />
            </g>
        </svg>
    );
}

export function EFAkout(props: any) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.061 5.0187C15.184 5.13557 15.259 5.29407 15.2714 5.46325C15.2838 5.63244 15.2326 5.80017 15.128 5.9337C14.6258 6.56368 14.0482 7.12959 13.408 7.6187L14.721 9.0267C14.833 9.15423 14.8935 9.31889 14.8908 9.4886C14.888 9.65831 14.8221 9.82091 14.706 9.9447C14.6521 10.0021 14.5871 10.0478 14.5148 10.0791C14.4426 10.1104 14.3647 10.1265 14.286 10.1265C14.2073 10.1265 14.1294 10.1104 14.0572 10.0791C13.9849 10.0478 13.9199 10.0021 13.866 9.9447L12.348 8.3167C11.6603 8.70639 10.9238 9.00299 10.158 9.1987L10.861 11.2647C10.975 11.6017 10.813 11.9747 10.499 12.0967C10.185 12.2197 9.83801 12.0467 9.72401 11.7087L8.94901 9.4227C8.31511 9.49365 7.67539 9.496 7.04099 9.4297L6.267 11.7097C6.152 12.0457 5.805 12.2197 5.491 12.0967C5.177 11.9747 5.01601 11.6017 5.13001 11.2647L5.827 9.2177C5.05488 9.02552 4.31172 8.73162 3.61701 8.3437L2.124 9.9437C2.06895 10.0026 2.00217 10.0492 1.92795 10.0807C1.85374 10.1121 1.77377 10.1276 1.69318 10.1262C1.6126 10.1248 1.53318 10.1065 1.46012 10.0725C1.38706 10.0384 1.32197 9.98947 1.26902 9.9287C1.1596 9.80394 1.09925 9.64365 1.09925 9.4777C1.09925 9.31176 1.1596 9.15147 1.26902 9.0267L2.551 7.6507C1.92775 7.18004 1.36337 6.63614 0.870001 6.0307C0.766565 5.89672 0.716649 5.72906 0.729956 5.56032C0.743263 5.39158 0.818833 5.23382 0.941992 5.1177C0.999594 5.06442 1.06749 5.02348 1.14149 4.99737C1.21548 4.97127 1.29401 4.96056 1.37229 4.96589C1.45057 4.97123 1.52695 4.9925 1.59672 5.02841C1.66648 5.06432 1.72818 5.1141 1.77802 5.1747C3.33802 7.0867 5.59101 8.1827 7.95801 8.1787C10.393 8.1787 12.655 7.0377 14.208 5.0907C14.2569 5.02653 14.3187 4.97331 14.3894 4.93449C14.4601 4.89567 14.5382 4.87211 14.6185 4.86533C14.6989 4.85854 14.7798 4.86869 14.856 4.89511C14.9322 4.92152 15.0021 4.96363 15.061 5.0187Z"
                fill="#ABABAB"
            />
        </svg>
    );
}

export function EFGoldCredit(props: any) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
            <symbol id="namespace" viewBox="0 0 15 15">
                <defs>
                    <path id="a" d="M0 0h15v15H0z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="b" fill="#fff"></mask>
                    <g stroke="#FB8814" mask="url(#b)">
                        <g transform="translate(2 2)">
                            <path d="M.275 8.556V1.71m9 0v1.437" strokeLinecap="round" />
                            <ellipse cx="4.775" cy="1.711" rx="4.5" ry="1.711" />
                            <path d="M.275 8.556c0 .945 2.015 1.71 4.5 1.71m-4.5-5.133c0 .945 2.015 1.711 4.5 1.711" strokeLinecap="round" />
                        </g>
                        <g transform="translate(8 6.4)">
                            <path d="M.171 5.5V1.1m5.596 0v4.4" />
                            <ellipse cx="2.969" cy="1.1" rx="2.798" ry="1.1" />
                            <path d="M.171 5.5c0 .608 1.253 1.1 2.798 1.1 1.545 0 2.798-.492 2.798-1.1M.171 3.3c0 .608 1.253 1.1 2.798 1.1 1.545 0 2.798-.492 2.798-1.1" />
                        </g>
                    </g>
                </g>
            </symbol>
        </svg>
    );
}

export function EFDeviceCloseH5(props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M29.601 15.46 23.061 22l6.54 6.54-1.06 1.061L22 23.061l-6.54 6.54-1.061-1.06L20.939 22l-6.54-6.54 1.06-1.061L22 20.939l6.54-6.54 1.061 1.06z"
                fill="#03060B"
                fillRule="evenodd"
            />
        </svg>
    );
}

export function EFDeleteForH5Dialog(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 44 44">
            <path
                d="M29.601 15.46 23.061 22l6.54 6.54-1.06 1.061L22 23.061l-6.54 6.54-1.061-1.06L20.939 22l-6.54-6.54 1.06-1.061L22 20.939l6.54-6.54 1.061 1.06z"
                fill="#03060B"
                fillRule="evenodd"
            />
        </svg>
    );
}

export function EFCloseIcon(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="10" fill="black" fillOpacity="0.4" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1872 7.53033C13.4801 7.23744 13.4801 6.76256 13.1872 6.46967C12.8943 6.17678 12.4194 6.17678 12.1265 6.46967L9.82873 8.76746L7.53094 6.46967C7.23805 6.17678 6.76317 6.17678 6.47028 6.46967C6.17739 6.76256 6.17739 7.23744 6.47028 7.53033L8.76807 9.82812L6.46967 12.1265C6.17678 12.4194 6.17678 12.8943 6.46967 13.1872C6.76256 13.4801 7.23744 13.4801 7.53033 13.1872L9.82873 10.8888L12.1271 13.1872C12.42 13.4801 12.8949 13.4801 13.1878 13.1872C13.4807 12.8943 13.4807 12.4194 13.1878 12.1265L10.8894 9.82812L13.1872 7.53033Z"
                fill="white"
            />
        </svg>
    );
}

export function EFLoginIcon1(props: any) {
    return (
        <svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.8335 12C18.2518 12 21.8335 10.2091 21.8335 8C21.8335 5.79086 18.2518 4 13.8335 4C9.41522 4 5.8335 5.79086 5.8335 8C5.8335 10.2091 9.41522 12 13.8335 12Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.8335 14.6667C5.8335 16.8759 9.41522 18.6667 13.8335 18.6667"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.8335 21.3333C5.8335 23.5424 9.41522 25.3333 13.8335 25.3333"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M5.8335 8V21.3333" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.8335 8V11.3333" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M21.8337 19.6667C24.5527 19.6667 26.7568 18.5475 26.7568 17.1667C26.7568 15.786 24.5527 14.6667 21.8337 14.6667C19.1148 14.6667 16.9106 15.786 16.9106 17.1667C16.9106 18.5475 19.1148 19.6667 21.8337 19.6667Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.9106 21.3333C16.9106 22.714 19.1148 23.8333 21.8337 23.8333C24.5527 23.8333 26.7568 22.714 26.7568 21.3333"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.9106 25.5C16.9106 26.8807 19.1148 28 21.8337 28C24.5527 28 26.7568 26.8807 26.7568 25.5"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16.9106 17.1667V25.5001" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.7568 17.1667V25.5001" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export function EFLoginIcon2(props: any) {
    return (
        <svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25 10C3.83579 10 3.5 10.3358 3.5 10.75C3.5 11.1642 3.83579 11.5 4.25 11.5H6.49509C6.82864 11.5 7.12346 11.7169 7.22279 12.0353L7.82742 13.9736L10.593 23.4802C10.874 24.4462 11.7592 25.1105 12.7652 25.1105H22.0435C23.0496 25.1105 23.9348 24.4462 24.2158 23.4802L26.6354 15.1626C26.6952 14.9573 26.7255 14.7445 26.7255 14.5307C26.7255 13.2812 25.7126 12.2684 24.4632 12.2684H8.86679L8.65474 11.5886C8.35995 10.6436 7.485 10 6.49509 10H4.25ZM9.53478 14.4726C9.53045 14.4176 9.51996 14.3623 9.50284 14.3074L9.3347 13.7684H24.4632C24.8842 13.7684 25.2255 14.1097 25.2255 14.5307C25.2255 14.6027 25.2153 14.6744 25.1951 14.7436L22.7755 23.0612C22.6808 23.3867 22.3825 23.6105 22.0435 23.6105H12.7652C12.4262 23.6105 12.128 23.3867 12.0333 23.0612L9.53478 14.4726Z"
                fill="#010101"
            />
            <path
                d="M12 29C12.8284 29 13.5 28.3284 13.5 27.5C13.5 26.6716 12.8284 26 12 26C11.1716 26 10.5 26.6716 10.5 27.5C10.5 28.3284 11.1716 29 12 29Z"
                fill="#010101"
            />
            <path
                d="M23 29C23.8284 29 24.5 28.3284 24.5 27.5C24.5 26.6716 23.8284 26 23 26C22.1716 26 21.5 26.6716 21.5 27.5C21.5 28.3284 22.1716 29 23 29Z"
                fill="#010101"
            />
            <path
                d="M13.375 6.5H16.7266M13.375 10.1241H16.7266M18.2734 6.5H21.625M13.375 8.36101H16.7266M18.2734 10.25V8.60713C18.2734 8.4712 18.3889 8.36101 18.5312 8.36101H21.625"
                stroke="#010101"
                strokeWidth="1.125"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 9C11.5 5.68629 14.1863 3 17.5 3C20.8137 3 23.5 5.68629 23.5 9C23.5 10.5367 22.9223 11.9385 21.9722 13H23.8454C24.5768 11.8423 25 10.4706 25 9C25 4.85786 21.6421 1.5 17.5 1.5C13.3579 1.5 10 4.85786 10 9C10 10.4706 10.4232 11.8423 11.1546 13H13.0278C12.0777 11.9385 11.5 10.5367 11.5 9Z"
                fill="black"
            />
        </svg>
    );
}

export function EFLoginIcon3(props: any) {
    return (
        <svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8005_3120)">
                <path
                    d="M16.4998 5.28707C16.8697 5.27384 17.2285 5.415 17.4902 5.67674L28.003 16.1895C28.5237 16.7102 28.5237 17.5544 28.003 18.0751L18.5749 27.5032C18.0542 28.0239 17.21 28.0239 16.6893 27.5032L6.17651 16.9904C5.91476 16.7287 5.77361 16.3699 5.78684 16L6.15662 5.65685L16.4998 5.28707Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.6987 10.1328C14.7594 9.07215 15.8879 9.02972 16.9571 10.0989C18.0517 11.1935 17.9499 12.3305 16.9231 13.3572C15.8625 14.4179 14.7339 14.4603 13.6648 13.3912C12.5702 12.2966 12.672 11.1595 13.6987 10.1328ZM20.5973 13.7391L21.3949 14.5367L11.7132 17.1416L10.9156 16.344L20.5973 13.7391ZM16.3037 10.7353C15.7946 10.2261 15.1158 10.4128 14.5473 10.9813C14.0636 11.465 13.7327 12.1693 14.3097 12.7463C14.8867 13.3233 15.591 12.9923 16.0746 12.5087C16.6007 11.9826 16.8638 11.2953 16.3037 10.7353ZM15.3873 17.5235C16.448 16.4628 17.5765 16.4204 18.6457 17.4895C19.7403 18.5841 19.6384 19.7212 18.6117 20.7479C17.5511 21.8086 16.4225 21.851 15.3534 20.7818C14.2588 19.6872 14.3606 18.5502 15.3873 17.5235ZM17.9923 18.1259C17.4832 17.6168 16.8044 17.8035 16.2358 18.372C15.7522 18.8557 15.4213 19.56 15.9983 20.137C16.5753 20.714 17.2795 20.383 17.7632 19.8994C18.2893 19.3733 18.5523 18.686 17.9923 18.1259Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_8005_3120">
                    <rect width="32" height="32" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function EFTooltipCheck(props: any) {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66666 8.00016L5.99999 11.3335L13.3333 4.3335" stroke="#30BF00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export function EFTooltipClose(props: any) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5L5 15" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 5L15 15" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
