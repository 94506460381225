'use client';
export const i18nText = {
    FACEBOOK: 'FACEBOOK',
    TWITTER: 'TWITTER',
    INSTAGRAM: 'INSTAGRAM',
    YOUTUBE: 'YOUTUBE',
    TIKTOK: 'TIKTOK',
    LINE: 'LINE',
    NAVER: 'NAVER',
    Bilibili: 'Bilibili',
    Weibo: 'Weibo',
    Wechat: 'Wechat',
};
