'use client';
import { useState } from 'react';
import EFHeaderThrIcon from './icons/EFHeaderThrIcon';
import styles from './index.module.scss';

const ToggleIcon = ({ callback }: any) => {
    const [isOpen, setIsOpen] = useState(false);

    // 手机端切换
    const toggleFn = () => {
        const isOpen2 = !isOpen;
        setIsOpen(isOpen2);
        callback && callback(isOpen2);
    };

    return (
        <div className={`header-tools-item header-tools-item-control`}>
            <div className={styles.tooltoggle} onClick={toggleFn}>
                <div className="navbar-control-item navbar-control-item1"></div>
                <div className="navbar-control-item navbar-control-item2"></div>
                <div className="navbar-control-item navbar-control-item3"></div>
                <EFHeaderThrIcon className="navbar-control-bg" />
            </div>
        </div>
    );
};

export default ToggleIcon;
