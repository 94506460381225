import { useRouter } from 'next/router';
import { useEffect } from 'react';
import BhStat from '.';

const isCypressTestFn = () => {
    if (typeof window !== 'undefined') {
        const userAgent = window?.navigator?.userAgent || '';
        const devices = userAgent.toLowerCase() || '';
        if (devices.indexOf('iscypresstest') > 0) {
            return true;
        }
    }
    return false;
};

export default function BhStatCommon() {
    const { locale = 'us' } = useRouter();
    // 是否是自动化脚本
    const isCypressTest = isCypressTestFn();
    useEffect(() => {
        if (!isCypressTest && locale === 'sa') {
            setTimeout(() => {
                BhStat.init({
                    locale,
                });
                window.addEventListener('beforeunload', () => {
                    BhStat.leavePage();
                });
                document.addEventListener('visibilitychange', () => {
                    if (document.visibilityState === 'hidden') {
                        BhStat.leavePage();
                    }
                });
            }, 2000);
        }
    }, []);

    return null;
}
