export default function IconClose() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="jsx-444279368">
            <g className="jsx-444279368">
                <path id="Vector 1" d="M1 1L21 21" stroke="#757575" className="jsx-444279368"></path>
                <path id="Vector 2" d="M21 1L1 21" stroke="#757575" className="jsx-444279368"></path>
            </g>
        </svg>
    );
}
