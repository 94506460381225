import TopBarCountdown from '@elem/compCommon/TopBarCountdown';
import { useRouter } from 'next/router';
import { langText, endTimeObj } from './langText';

export const TopBarCountdownLocals: any = ['jp'];
export const TopBarCountdownPages: any = ['/login', '/register']; //不显示页面

export default function TopBarCountdownJp() {
    const { locale = 'jp', pathname, query } = useRouter();
    const { time, timer } = query;
    const debugTime = time ? new Date(time?.toString())?.getTime() : timer ? new Date(timer?.toString())?.getTime() : 0;
    const textA = langText[locale] || langText['jp'];
    const timeA = endTimeObj[locale] || endTimeObj['jp'];
    if (TopBarCountdownPages.includes(pathname)) {
        return null;
    }
    return (
        <>
            <TopBarCountdown
                debugTime={debugTime}
                endTime={timeA.endTime}
                href={`/${locale}/delta-pro-3-portable-power-station`}
                title={textA.title}
                sub_title={textA.sub_title}
                time_front_tips={textA.time_front_tips}
                time_front_tips2={textA.time_front_tips2}
                time_day={textA.time_day}
                time_hour={textA.time_hour}
                time_minute={textA.time_minute}
                time_second={textA.time_second}
            />
        </>
    );
}
