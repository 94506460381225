export const configs = {
    includePages: [
        '/recreational-activities',
        '/power-backup',
        '/vanlife-roadtrips',
        '/whole-home-backup-power-solution',
        '/delta-pro-portable-power-station',
        '/cost-saving',
        '/living-off-the-grid',
    ],
    includeSites: ['us'],
    times: {
        us: { start: '2024/09/23 15:00:00 GMT+8', end: '2024/10/08 15:00:00 GMT+8' },
    },
};
