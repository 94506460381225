'use client';
export const i18nText: any = {
    en: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow All Rights Reserved.',
        site_bottom_select_tip: 'Select your country or region:',
        site_bottom_subscription_tip_four: 'Something went wrong',
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
        site_bottom_subscription_sms_phone_thank: 'Thank you for subscribing!',
    },
    us: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow Technology Inc. All Rights Reserved.',
        site_bottom_select_tip: 'Select your country or region:',
        site_bottom_subscription_tip_four: 'Something went wrong',
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
        site_bottom_subscription_sms_phone_thank: 'Thank you for subscribing!',
    },
    cis: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow Все права защищены.',
        site_bottom_select_tip: 'Выберите страну или регион:',
        site_bottom_subscription_tip_four: 'Что-то пошло не так',
        site_bottom_subscription_tip_one: 'Этот адрес уже используется, укажите другой адрес эл. почты',
        site_bottom_subscription_tip_three: 'Благодарим за подписку.',
        site_bottom_subscription_email: 'Адрес эл. почты',
        site_bottom_subscription_tip_two: 'Укажите действующий адрес эл. почты',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_sms_phone_thank: 'Thank you for subscribing!',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
    },
    cn: {
        site_bottom_copyright: '版权所有 © {nowYear} 深圳市正浩创新科技股份有限公司保留所有权利。',
        site_bottom_select_tip: '选择您的国家或地区：',
        site_bottom_subscription_tip_four: '出错了',
        site_bottom_subscription_tip_one: '此电子邮件已被订阅，请使用其他电子邮件地址',
        site_bottom_subscription_tip_three: '感谢您的订阅！',
        site_bottom_subscription_email: '电子邮件地址',
        site_bottom_subscription_tip_two: '请输入有效的电子邮件地址',
        site_bottom_subscription_tip_five: '请勾选至少一种场景进行订阅',
        site_bottom_subscription_email_protocol: '您同意接收来自正浩创新的新闻通讯和营销电子邮件',
        site_bottom_subscription_sms_phone_thank: '感谢您的订阅！',
    },
    de: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow Alle Rechte vorbehalten.',
        site_bottom_select_tip: 'Wählen Sie Ihr Land oder Ihre Region aus:',
        site_bottom_subscription_tip_four: 'Es ist ein Fehler aufgetreten',
        site_bottom_subscription_tip_one: 'Diese E-Mail wurde bereits abonniert. Verwenden Sie eine andere E-Mail-Adresse',
        site_bottom_subscription_tip_three: 'Vielen Dank für Ihre Registrierung!',
        site_bottom_subscription_email: 'E-Mail-Adresse',
        site_bottom_subscription_tip_two: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
        site_bottom_subscription_tip_five: 'Bitte kreuzen Sie mindestens ein Szenario an, um sich anzumelden.',
        site_bottom_subscription_email_protocol: 'Ich bin mit der Zusendung des EcoFlow Newsletters einverstanden.',
        site_bottom_subscription_sms_phone_thank: 'Vielen Dank für Ihre Anmeldung!',
        site_bottom_subscription_email_protocol_hint: 'Bitte ankreuzen, um fortzufahren.',
    },
    es: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow. Todos los derechos reservados.',
        site_bottom_select_tip: 'Selecciona tu país o región:',
        site_bottom_subscription_tip_four: 'Se ha producido un error',
        site_bottom_subscription_tip_one: 'Este correo electrónico ya se ha suscrito. Utiliza otra dirección de correo electrónico',
        site_bottom_subscription_tip_three: '¡Gracias por suscribirte!',
        site_bottom_subscription_email: 'Dirección de correo electrónico',
        site_bottom_subscription_tip_two: 'Introduce una dirección de correo electrónico válida',
        site_bottom_subscription_tip_five: 'Seleccione al menos un escenario para inscribirse.',
        site_bottom_subscription_email_protocol: 'Aceptas recibir boletines y correos electrónicos de marketing de EcoFlow.',
        site_bottom_subscription_sms_phone_thank: '¡Gracias por suscribirte!',
        site_bottom_subscription_email_protocol_hint: 'Marca la casilla para continuar.',
    },
    fr: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow. Tous droits réservés.',
        site_bottom_select_tip: 'Sélectionnez votre pays ou région :',
        site_bottom_subscription_tip_four: "Une erreur s'est produite",
        site_bottom_subscription_tip_one: 'Cette adresse e-mail a déjà été enregistrée. Veuillez en utiliser une autre.',
        site_bottom_subscription_tip_three: 'Merci de vous être abonné(e) !',
        site_bottom_subscription_email: 'Adresse e-mail',
        site_bottom_subscription_tip_two: 'Veuillez saisir une adresse e-mail valide.',
        site_bottom_subscription_tip_five: 'Veuillez cocher au moins un scénario pour vous abonner.',
        site_bottom_subscription_email_protocol: "Vous acceptez de recevoir la newsletter d'EcoFlow.",
        site_bottom_subscription_sms_phone_thank: 'Merci de votre abonnement !',
        site_bottom_subscription_email_protocol_hint: 'Veuillez cocher la case pour continuer.',
    },
    it: {
        site_bottom_copyright:
            'Copyright © {nowYear} EcoFlow Tutti i diritti riservati.\n\nEcoFlow Europe S.R.O.\nPartita IVA: CZ13974203\nIndirizzo: Doubravice 110, 53353 Pardubice, Repubblica Ceca',
        site_bottom_select_tip: 'Seleziona il Paese o la regione:',
        site_bottom_subscription_tip_four: 'Si è verificato un errore',
        site_bottom_subscription_tip_one: 'Questa e-mail è già stata registrata. Utilizza un altro indirizzo e-mail',
        site_bottom_subscription_tip_three: 'Grazie per esserti iscritto！',
        site_bottom_subscription_email: 'Indirizzo e-mail',
        site_bottom_subscription_tip_two: 'Inserisci un indirizzo e-mail valido',
        site_bottom_subscription_tip_five: 'Si prega di selezionare almeno uno scenario per iscriverti.',
        site_bottom_subscription_email_protocol: 'Accetti di ricevere newsletter ed e-mail di marketing da parte di EcoFlow.',
        site_bottom_subscription_sms_phone_thank: 'Grazie per avere effettuato l’iscrizione!',
        site_bottom_subscription_email_protocol_hint: 'Si prega di spuntare la casella per procedere.',
    },
    jp: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow All Rights Reserved.',
        site_bottom_select_tip: '国または地域の選択：',
        site_bottom_subscription_tip_four: '問題が発生しました',
        site_bottom_subscription_tip_one: 'このメールアドレスは登録されています。別のメールアドレスを使用してください',
        site_bottom_subscription_tip_three: 'ご登録ありがとうございます。',
        site_bottom_subscription_email: 'メールアドレスをご記入ください',
        site_bottom_subscription_tip_two: 'メールアドレスの形式が正しくありません',
        site_bottom_subscription_tip_five: '購読するには少なくとも 1 つのシナリオにチェックを入れてください。',
        site_bottom_subscription_email_protocol: 'EcoFlowからニュースレターやプロモーションを受信します',
        site_bottom_subscription_sms_phone_thank: 'ご登録いただきありがとうございます！',
        site_bottom_subscription_email_protocol_hint: '次に進むには同意するにチェックを入れてください。',
    },
    kr: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow All Rights Reserved.',
        site_bottom_select_tip: '국가 및 지역을 선택하세요.',
        site_bottom_subscription_tip_four: '문제가 발생했습니다.',
        site_bottom_subscription_tip_one: '이 이메일은 이미 구독 중입니다. 다른 이메일 주소를 사용해 주십시오.',
        site_bottom_subscription_tip_three: '구독해주셔서 감사합니다!',
        site_bottom_subscription_email: '이메일 주소',
        site_bottom_subscription_tip_two: '유효한 이메일 주소를 입력하세요',
        site_bottom_subscription_tip_five: '구독하려면 시나리오를 하나 이상 선택하세요.',
        site_bottom_subscription_email_protocol: 'EcoFlow의 뉴스레터 및 마케팅 이메일을 수신하는 데 동의합니다.',
        site_bottom_subscription_sms_phone_thank: '구독해 주셔서 감사합니다!',
        site_bottom_subscription_email_protocol_hint: '계속 진행하려면 동의 확인란을 선택하세요.',
    },
    sa: {
        site_bottom_copyright: 'حقوق النشر © {nowYear} لصالح EcoFlow كل الحقوق محفوظة.',
        site_bottom_select_tip: 'حدد بلدك أو منطقتك:',
        site_bottom_subscription_tip_four: 'حدث خطأ ما',
        site_bottom_subscription_tip_one: 'عنوان ​​البريد الإلكتروني هذا مشترك بالفعل. يُرجى استخدام عنوان بريد إلكتروني آخر',
        site_bottom_subscription_tip_three: 'شكرًا لك على الاشتراك!',
        site_bottom_subscription_email: 'عنوان البريد الإلكتروني',
        site_bottom_subscription_tip_two: 'يُرجى إدخال عنوان بريد إلكتروني صالح',
        site_bottom_subscription_tip_five: 'يرجى تحديد سيناريو واحد على الأقل للاشتراك.',
        site_bottom_subscription_email_protocol: 'أنت توافق على تلقي الرسائل الإخبارية ورسائل البريد الإلكتروني التسويقية من EcoFlow.',
        site_bottom_subscription_sms_phone_thank: 'شكرا لك على الاشتراك!',
    },
    tw: {
        site_bottom_copyright: '版權所有 © {nowYear} 深圳市正浩創新科技股份有限公司保留所有權利',
        site_bottom_select_tip: '選擇您的國家或地區：',
        site_bottom_subscription_tip_four: '發生錯誤',
        site_bottom_subscription_tip_one: '此電子郵件已訂閱過，請使用其他電子郵件地址',
        site_bottom_subscription_tip_three: '感謝您的訂閱！',
        site_bottom_subscription_email: '電子郵件地址',
        site_bottom_subscription_email_protocol: '您同意接收來自 EcoFlow 的電子報和行銷電子郵件。',
        site_bottom_subscription_sms_phone_thank: '感謝您的訂閱！',
    },
    br: {
        site_bottom_copyright: 'Copyright © {nowYear} EcoFlow Todos os direitos reservados.',
        site_bottom_select_tip: 'Selecione seu país ou região:',
        site_bottom_subscription_tip_four: 'Algo deu errado',
        site_bottom_subscription_tip_one: 'Este e-mail foi inscrito, por favor use outro endereço de e-mail',
        site_bottom_subscription_tip_three: 'Obrigado por subscrever!',
        site_bottom_subscription_email: 'Endereço de email',
        site_bottom_subscription_tip_two: 'Por favor, insira um endereço de e-mail válido',
        site_bottom_subscription_tip_five: 'Marque pelo menos um cenário para se inscrever.',
        site_bottom_subscription_email_protocol: 'Você concorda em receber emaisl de boletins e marketing da EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Marque a caixa de seleção para prosseguir.',
        site_bottom_subscription_sms_phone_thank: 'Obrigado por assinar!',
    },
};
