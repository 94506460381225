'use client';
import { useState, useEffect } from 'react';
import { i18nText } from '../i18nText';
import { useGlobal } from '@/utils/useGlobal';
import EFEmail from '@/components/UI/svg/Footer/Icon/EFEmail';
import { subscribe, searchSubInfo } from '@/service';
import EFTick from '@/components/UI/svg/Footer/Icon/EFTick';
import gtm from '@/utils/gtm';

const EmailForm = ({
    locale,
    setEmailSubscription,
    smsSuccess,
    setSmsSuccess,
}: {
    locale: string;
    setEmailSubscription: any;
    setSmsSuccess: any;
    smsSuccess: string;
}) => {
    const { setGlobalContext, subEmailCount } = useGlobal();
    const [inputMsg, setInputMsg] = useState('');
    const [email, setEmail] = useState('');
    const [sms, setSms] = useState(false);
    const [emailsProtocol, setEmailsProtocol] = useState(false);
    const [inputTipClassName, setInputTipClassName] = useState('');

    const defaultCheckedProtocol = ['us', 'ca', 'jp', 'au'];

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!validEmail(email) || !validEmailsProtocol()) {
            gtm.push({
                event: 'uaEvent',
                event_name: 'email_submit',
                button_name: 'submit',
                status: 'fail',
            });
            return;
        }
        const searchRes = await searchSubInfo({ email }, locale);

        if (searchRes.code === '0') {
            if (searchRes.data.acceptsMarketing && searchRes.data.acceptsMarketingSms) {
                setInputMsg(i18nText[locale]?.site_bottom_subscription_sms_phone_thank || i18nText.en.site_bottom_subscription_sms_phone_thank);
                resetMessageStyle('success');
                return;
            }
            if (searchRes.data.acceptsMarketing && !searchRes.data.acceptsMarketingSms) {
                setEmailSubscription(email);
                setSms(false);
                return;
            }
        }

        const subscribeRes = await subscribe(email, locale, 'WEB_FOOTER');
        setInputMsg(
            subscribeRes?.code === '0'
                ? i18nText[locale]?.site_bottom_subscription_tip_three || i18nText.en.site_bottom_subscription_tip_three
                : subscribeRes?.code === '1000'
                ? i18nText[locale]?.site_bottom_subscription_tip_one || i18nText.en.site_bottom_subscription_tip_one
                : subscribeRes?.message || i18nText[locale]?.site_bottom_subscription_tip_four || i18nText.en.site_bottom_subscription_tip_four,
        );
        setEmailSubscription(email);
        setEmail('');
        gtm.push({
            event: 'uaEvent',
            event_name: 'email_submit',
            button_name: 'submit',
            status: 'success',
        });
        if (subscribeRes?.code === '0') {
            setGlobalContext({ subEmailCount: subEmailCount + 1 });
            setInputTipClassName('input-msg-au');
        }
    };
    const handleEmail = (e: any) => {
        const emailVal = e.target.value.trim();
        setEmail(emailVal);
        validEmail(emailVal);
    };

    const handleProtocol = (e) => {
        setEmailsProtocol(e.target.checked);
    };

    const validEmail = (_email: string) => {
        resetMessageStyle('error');
        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(_email)) {
            setInputMsg(i18nText[locale]?.site_bottom_subscription_tip_two || i18nText.en.site_bottom_subscription_tip_two);
            return;
        }
        setInputMsg('');
        return true;
    };

    const validEmailsProtocol = () => {
        resetMessageStyle('error');
        if (!emailsProtocol) {
            setInputMsg(i18nText[locale]?.site_bottom_subscription_email_protocol_hint || i18nText['en']?.site_bottom_subscription_email_protocol_hint);
            return false;
        }
        setInputMsg('');
        return true;
    };

    const resetMessageStyle = (status: string) => {
        if (status === 'success') {
            setInputTipClassName('input-msg-au');
            setSms(true);
        }
        if (status === 'error') {
            setInputTipClassName('');
            setSms(false);
        }
    };

    useEffect(() => {
        if (defaultCheckedProtocol.includes(locale)) {
            setEmailsProtocol(true);
        }
        setEmailSubscription('');
        if (smsSuccess) {
            resetMessageStyle('success');
            setInputMsg(smsSuccess);
            setSmsSuccess('');
        }
    }, []);

    return (
        <div className="right">
            <form className="sub">
                <div className="form-email-box">
                    <input
                        className="input-email"
                        id="emailInput"
                        type="text"
                        value={email}
                        placeholder={i18nText[locale]?.site_bottom_subscription_email || i18nText.en.site_bottom_subscription_email}
                        aria-label={i18nText[locale]?.site_bottom_subscription_email || i18nText.en.site_bottom_subscription_email}
                        onChange={handleEmail}
                    />
                    <button
                        className="form-submit"
                        type="button"
                        onClick={handleSubmit}
                        aria-label={i18nText[locale]?.site_bottom_subscription_email || i18nText.en.site_bottom_subscription_email}
                    >
                        <EFEmail className="form-email" />
                    </button>
                </div>
                {inputMsg && (
                    <div className={`input-msg ${inputTipClassName}`}>
                        {sms && <EFTick />}
                        {inputMsg}
                    </div>
                )}
                <div className="result"></div>
                <div className="emails-protocol">
                    <input type="checkbox" className="emails-check" id="emailsProtocol" onChange={handleProtocol} checked={emailsProtocol} />
                    <label htmlFor="emailsProtocol">
                        {i18nText[locale]?.site_bottom_subscription_email_protocol || i18nText['en']?.site_bottom_subscription_email_protocol}
                    </label>
                </div>
            </form>
        </div>
    );
};

export default EmailForm;
