export default function (props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="currentColor" width="1em" height="1em" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0ZM12.2164 15.2598L6.03901 7H10.8001L14.8499 12.415L19.8621 7H21.2612L15.475 13.251L22 21.9754H17.2389L12.8415 16.0958L7.39907 21.9754H6L12.2164 15.2598ZM10.2837 8.03056H8.09645L17.755 20.9447H19.9422L10.2837 8.03056Z"
                fill="currentColor"
            />
        </svg>
    );
}
