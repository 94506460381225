'use client';
export default function Logo({ ...props }: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none" {...props}>
            <path
                d="M18 9V17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17V9"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 1H13V6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6V1Z"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.50001 1H7.00001V5.5C7.00001 7.433 5.433 9 3.50001 9C2.2358 9 1.21095 7.97516 1.21095 6.71095C1.21095 6.39515 1.2763 6.08277 1.40287 5.79345L3.50001 1Z"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 1H16.5L18.5971 5.79345C19.1039 6.95166 18.5757 8.30136 17.4175 8.80808C17.1282 8.93466 16.8158 9 16.5 9C14.567 9 13 7.433 13 5.5V1Z"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
